import React, { useEffect, useState } from "react";
import { Modal, Carousel, Image } from "react-bootstrap";
import "./ModalCarousel.css";

const ModalCarousel = ({ show, handleClose, carouselData, index }) => {
  const [activeIndex, setActiveIndex] = useState(index);

  useEffect(() => {
    setActiveIndex(index);
  }, [index]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(activeIndex)}
      size="xl"
      dialogClassName="modal-fullscreen"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Carousel
          activeIndex={activeIndex}
          onSelect={handleSelect}
          variant="dark"
          interval={null}
        >
          {carouselData.map((item, index) => (
            <Carousel.Item key={index}>
              <Image
                src={item.link}
                alt={`Product  ${index}`}
                className="modalPage-carousel-image d-block w-100"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCarousel;
