export const storeData = (key, value) => {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (error) {
      // Saving error
      console.error(error);
    }
  };
  
  export const getData = (key) => {
    try {
      const jsonValue = localStorage.getItem(key);
      return jsonValue ? JSON.parse(jsonValue) : null;
    } catch (error) {
      // Error reading value
      console.error(error);
      return null;
    }
  };
  
  export const removeValue = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      // Remove error
      console.error(error);
    }
  };
  