import React from "react";
import ReactMarkdown from "react-markdown";
import "./TermsOfUse.css";
import useLanguage from "../../hooks/useLanguage";

export default function TermsOfUse() {
  const { termsOfUse } = useLanguage();
  return (
    <div className="termsOfUseContainer">
      <ReactMarkdown children={termsOfUse} />
    </div>
  );
}
