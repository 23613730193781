import React from "react";
import { Modal, Image, Container, Row, Col } from "react-bootstrap";
import "./ModalCarousel.css";
import useLanguage from "../hooks/useLanguage";

const DownloadAppModal = ({ show, handleClose, message }) => {
  const { selectedLanguage } = useLanguage();

  return (
    <Modal show={show} onHide={handleClose} animation={false} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container className="text-center">
          <Row style={{ paddingBottom: "10px" }}>
            <Col>{message}</Col>
          </Row>
          <Row>
            <Col>
              <a href="https://apps.apple.com/us/app/4her/id1669003704">
                <Image
                  src={
                    selectedLanguage === "en"
                      ? require("../en-btn-apple-store.png")
                      : require("../bg-btn-apple-store.png")
                  }
                  alt="Apple store download button"
                  fluid
                  style={{ height: "100%", width: "50%" }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.forher.forher">
                <Image
                  src={
                    selectedLanguage === "en"
                      ? require("../en-btn-google-play.png")
                      : require("../bg-btn-google-play.png")
                  }
                  alt="Google play store download button"
                  fluid
                  style={{ height: "100%", width: "50%" }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DownloadAppModal;
