import React, { createContext, useContext, useState } from "react";
import { Routes } from "../common/routes";
import { Methods } from "../common/methods";
import useAuth from "./useAuth";

const ProductContext = createContext({});

export const ProductProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [myProducts, setMyProducts] = useState([]);

  const { makeRequest } = useAuth();

  const getCategories = async () => {
    const res = await makeRequest(Methods.GET, Routes.CATEGORIES);
    setCategories([...res]);
    return res;
  };

  const getProducts = async (filters) => {
    const res = await makeRequest(Methods.POST, Routes.PRODUCTS, filters);
    setAllProducts([...res]);
    return res;
  };

  const getProduct = async (productId) => {
    const res = await makeRequest(Methods.GET, Routes.PRODUCTVIEWS + productId);
    setProduct(res);
    return res;
  };

  const getFavourites = async () => {
    const res = await makeRequest(Methods.GET, Routes.FAVOURITE);
    if (res) {
      setFavourites(res);
    }
  };

  const addOrRemovFromFavourites = async (productId, isFavourite) => {
    if (isFavourite) {
      await makeRequest(Methods.DELETE, Routes.FAVOURITE + productId, {});
    } else {
      await makeRequest(Methods.POST, Routes.FAVOURITE + productId, {});
    }
    await getFavourites();
  };

  const getMyProducts = async () => {
    const res = await makeRequest(Methods.GET, Routes.MYPRODUCTS);
    setMyProducts([...res]);
  };

  const removeMyProducts = () => {
    setMyProducts([]);
  };

  return (
    <ProductContext.Provider
      value={{
        getCategories,
        getProducts,
        categories,
        allProducts,
        product,
        getProduct,
        favourites,
        getFavourites,
        addOrRemovFromFavourites,
        myProducts,
        getMyProducts,
        removeMyProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default function useProduct() {
  return useContext(ProductContext);
}
