import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import useLanguage from "../hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

function NavBar() {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return (
    <Navbar
      fixed="bottom"
      style={{
        backgroundColor: "#99EDC3",
        justifyContent: "space-between",
      }}
      variant="white"
    >
      <Container className="app-footer">
        <div className="footerLogo-container">
          <img
            alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
            src={require("../4her-logo.png")}
            width="100"
            height="40"
          />
        </div>
        <Container className="allRights-container d-flex m-0 w-100 justify-content-center">
          <div className="d-flex w-100 justify-content-center">
            <Navbar.Brand
              className="allRightsReserved-container"
              style={{ color: "#373737" }}
            >
              {language.allRightsReserved}
            </Navbar.Brand>
          </div>
        </Container>
        <div className="footerLinks-container">
          <span onClick={() => navigate("/terms")} className="footer-link">
            {language.termsOfUse}
          </span>
          <span onClick={() => navigate("/privacy")} className="footer-link">
            {language.privacyPolicy}
          </span>
          <span onClick={() => navigate("/help")} className="footer-link">
            {language.help}
          </span>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
