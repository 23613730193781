import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ConfirmAccountPage.css";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import useAuth from "../../hooks/useAuth";
import { BsEnvelopeFill, BsFillLockFill } from "../../icons";
import { Button } from "react-bootstrap";
import { Methods } from "../../common/methods";
import { Routes } from "../../common/routes";
import { StorageKeys } from "../../common/storageKeys";
import { storeData } from "../../utils/localStorage";
import Spinner from "react-bootstrap/Spinner";
import Nav from "react-bootstrap/Nav";
import useLanguage from "../../hooks/useLanguage";

const ConfirmAccountPage = () => {
  const navigate = useNavigate();
  const {
    user,
    setCurrUser,
    makeRequest,
    ejectBearerInterceptor,
    initializeBearerInterceptor,
  } = useAuth();
  const { language } = useLanguage();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(user?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendCode = async () => {
    setIsCodeSend(true);
    await makeRequest(Methods.POST, Routes.SEND_CONFIRMATION_MAIL, {
      email,
    });
  };

  const confirmMail = async () => {
    setIsLoading(true);
    try {
      const res = await makeRequest(Methods.POST, Routes.CONFIRM_MAIL, {
        email,
        token: resetCode,
      });
      setCurrUser(res);
      ejectBearerInterceptor();
      initializeBearerInterceptor(res.token, res.refreshToken);
      await storeData(StorageKeys.TOKEN, res.token);
      await storeData(StorageKeys.REFRESH_TOKEN, res.refreshToken);
      navigate("/");
    } catch (err) {
      alert("Something went wrong !");
    } finally {
      setIsLoading(false);
    }
  };

  const isCodeValid = (code) => {
    if (code.length) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  if (isLoading) {
    return (
      <div className="confirmAccountPage-container d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="confirmAccountPage-container">
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col
            lg={6}
            xl={7}
            className="order-2 order-md-1 d-flex justify-content-center align-items-center"
          >
            <Image
              src={require("../../4her-logo.png")}
              alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
              fluid
              className="confirmAccountPage-logo"
            />
          </Col>
          <Col lg={6} xl={5} className="mt-md-0 order-1 order-md-2">
            <h3 className="pb-2">{language.yourEmail}:</h3>
            <Form.Group controlId="email" className="pb-2">
              <div className="icon-input-group">
                <BsEnvelopeFill className="input-icon" color="#C2AFF0" />
                <Form.Control type="email" value={email} disabled={true} />
              </div>
            </Form.Group>
            {isCodeSend ? (
              <h5 className="pb-2 d-flex align-items-center">
                <span>{language.youDidntReceiveCode} </span>
                <Nav.Link
                  style={{
                    color: "#C2AFF0",
                    textDecoration: "none",
                    fontWeight: "bold",
                    paddingLeft: "4px",
                  }}
                  onClick={async () => await sendCode()}
                >
                  {language.sendAgain}
                </Nav.Link>
              </h5>
            ) : (
              <Button
                variant="primary"
                onClick={async () => await sendCode()}
                className="mt-2 w-100"
                style={{
                  backgroundColor: "#99EDC3",
                  border: "none",
                  color: "#373737",
                  fontWeight: "bold",
                }}
              >
                {language.sendCode}
              </Button>
            )}
            {isCodeSend ? (
              <>
                <Form.Group controlId="code" className="pb-2">
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#C2AFF0" />
                    <Form.Control
                      type="textarea"
                      placeholder="Enter code"
                      value={resetCode}
                      style={{
                        backgroundColor: "#F7F0F5",
                        border: "none",
                      }}
                      className="confirmAccountPage-formControl"
                      onChange={(e) => {
                        setResetCode(e.target.value, "code");
                        isCodeValid(e.target.value, "code");
                      }}
                    />
                  </div>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={async () => await confirmMail()}
                  className="mt-2 w-100"
                  style={{
                    backgroundColor: "#99EDC3",
                    border: "none",
                    color: "#373737",
                    fontWeight: "bold",
                  }}
                  disabled={!isValid}
                >
                  {language.confirmEmail}
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmAccountPage;
