import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Methods } from "../common/methods";
import { removeValue, storeData, getData } from "../utils/localStorage";
import { StorageKeys } from "../common/storageKeys";
import { Routes } from "../common/routes.js";

const AuthContext = createContext({});

const baseURL = "https://for-her-shop-v2.herokuapp.com/api/v1/";
// const baseURL = "http://localhost:3000/api/v1/";

const api = axios.create({ baseURL });

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isApiInitialized, setApiInitialized] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    const initializeApi = async () => {
      const data = getData(StorageKeys.USER);
      if (data) {
        setUser(data);
        ejectBearerInterceptor();
        ejectRefreshInterceptor();

        const token = getData(StorageKeys.TOKEN);
        const refreshToken = getData(StorageKeys.REFRESH_TOKEN);
        initializeUserId(data.id);
        initializeBearerInterceptor(token, refreshToken);
        initializeRefreshInterceptor();
      }

      setApiInitialized(true);
    };

    initializeApi().then(executePendingRequests);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeBearerInterceptor = (token, refreshToken) => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.refreshtoken = refreshToken;
      return config;
    });
  };

  const initializeUserId = (userid) => {
    api.defaults.headers.common["userid"] = userid;
  };

  const initializeRefreshInterceptor = () => {
    api.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (err) => {
        if (err.response.status === 401) {
          try {
            ejectBearerInterceptor();
            ejectRefreshInterceptor();
            removeValue(StorageKeys.USER);
            removeValue(StorageKeys.TOKEN);
            removeValue(StorageKeys.REFRESH_TOKEN);
            setUser(null);
            alert("Session expired please login again");
            return Promise.reject(err);
          } catch (err) {
            return Promise.reject(err);
          }
        }
        if (err.response.status === 498) {
          const newToken = await api.get(Routes.REFRESH);


          storeData(StorageKeys.TOKEN, newToken.data.token);
          storeData(StorageKeys.REFRESH_TOKEN, newToken.data.refreshToken);

          api.interceptors.request.clear();
          initializeBearerInterceptor(newToken.data.token, newToken.data.refreshToken);

          const config = err.response.config;

          const method = config.method;
          const url = config.url.replace(baseURL, "");

          let body = {};
          if (config.data) {
            body = JSON.parse(config.data);
          }
          try {
            return api[method](url, body);
          } catch (err) {
            Promise.reject(err);
          }
        }

        return err.response;
      }
    );
  };

  const ejectBearerInterceptor = () => {
    api.interceptors.request.clear();
  };

  const ejectRefreshInterceptor = () => {
    api.interceptors.response.clear();
  };

  const makeRequest = async (method, route, body = {}) => {
    if (isApiInitialized) {
      try {
        const res = await api[method](`${baseURL + route}`, body);
        if (res.status === 500) {
          return Promise.reject(res.data);
        }
        return res.data;
      } catch (err) {
        console.error(route, err.response);
        return Promise.reject(err?.response?.data);
      }
    } else {
      setPendingRequests((prevRequests) => [
        ...prevRequests,
        { method, route, body },
      ]);
    }
  };

  const executePendingRequests = async () => {
    for (const request of pendingRequests) {
      await makeRequest(request.method, request.route, request.body);
    }
    setPendingRequests([]);
  };

  const logIn = async (route, payload) => {
    try {
      const res = await makeRequest(Methods.POST, route, payload);
      setUser(res);
      storeData(StorageKeys.USER, res);
      storeData(StorageKeys.TOKEN, res.token);
      storeData(StorageKeys.REFRESH_TOKEN, res.refreshToken);
      initializeRefreshInterceptor();
      initializeUserId(res.id);
      initializeBearerInterceptor(res.token, res.refreshToken);
      return res;
    } catch (err) {
      setUser(null);
      return Promise.reject(err);
    }
  };

  const signOut = async () => {
    try {
      await makeRequest(Methods.POST, Routes.LOGOUT, {});
    } catch (err) {
      return Promise.reject(err);
    } finally {
      ejectBearerInterceptor();
      ejectRefreshInterceptor();

      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
    }
  };

  const setCurrUser = async (user) => {
    setUser(user);
    storeData(StorageKeys.USER, user);
  };

  return (
    <AuthContext.Provider
      value={{
        makeRequest,
        logIn,
        signOut,
        ejectBearerInterceptor,
        initializeBearerInterceptor,
        initializeUserId,
        user,
        setCurrUser,
      }}
    >
      {isApiInitialized ? children : null}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
