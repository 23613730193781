import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import { BsFillLockFill } from "../../icons";
import "./SettingsPage.css";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../common/routes";
import useProduct from "../../hooks/useLanguage";
import { Button } from "react-bootstrap";
import { Methods } from "../../common/methods";
import { StorageKeys } from "../../common/storageKeys";
import { storeData } from "../../utils/localStorage";
import Alert from "../../components/Alert";
import { AiFillEdit, AiOutlineClose, AiOutlineCheck } from "../../icons";
import Spinner from "react-bootstrap/Spinner";

const SettingsPage = () => {
  const {
    user,
    makeRequest,
    ejectBearerInterceptor,
    initializeBearerInterceptor,
    signOut,
    setCurrUser,
  } = useAuth();
  const { language } = useProduct();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({ message: "", variant: "", show: false });
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");
  const [inputs, setInputs] = React.useState({
    oldPassword: "",
    password: "",
    retypePassword: "",
  });

  const [isValid, setIsValid] = React.useState({
    oldPassword: false,
    password: false,
    retypePassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const clearInputs = () => {
    setInputs({
      oldPassword: "",
      password: "",
      retypePassword: "",
    });
    setIsValid({
      oldPass: false,
      pass: false,
      retypePass: false,
    });
  };

  const handleOnChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const handleNameChange = (text) => {
    setNewName(text);
  };

  const handleNewName = async () => {
    try {
      setIsLoading(true);
      const res = await makeRequest(Methods.PUT, Routes.CHANGE_NAME, {
        name: newName,
      });
      await storeData(StorageKeys.USER, res);
      setCurrUser(res);
      setNewName("");
    } catch (err) {
      // setError"Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const validateInputs = (type, input) => {
    const passRegex = new RegExp(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    );
    if (type === "oldPassword") {
      setIsValid({
        oldPassword: passRegex.test(input),
        password: isValid.password,
        retypePassword: isValid.retypePassword,
      });
    }
    if (type === "password") {
      setIsValid({
        oldPassword: isValid.oldPassword,
        password: passRegex.test(input),
        retypePassword: isValid.retypePassword,
      });
    }

    if (type === "retypePassword") {
      setIsValid({
        oldPassword: isValid.oldPassword,
        password: isValid.password,
        retypePassword: passRegex.test(input),
      });
    }
  };

  const handleSubmit = async () => {
    if (inputs.password === inputs.retypePassword) {
      try {
        setIsLoading(true);
        const res = await makeRequest(Methods.POST, Routes.RESET_PASS, {
          email: user?.email,
          oldPassword: inputs.oldPassword,
          password: inputs.password,
          confirmPassword: inputs.retypePassword,
        });
        setAlert({ message: "Successfull", variant: "primary", show: true });
        ejectBearerInterceptor();
        initializeBearerInterceptor(res.token, res.refreshToken);
        storeData(StorageKeys.TOKEN, res.token);
        storeData(StorageKeys.REFRESH_TOKEN, res.refreshToken);
        clearInputs();
        navigate("/");
      } catch (err) {
        setAlert({
          message: "An Error has occured",
          variant: "Danger",
          show: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Alert(language.alertPasswordsDoNotMatch);
    }
  };

  const handleDeleteAcc = async () => {
    const confirmDelete = window.confirm(
      "Are you sure? This action cannot be undone"
    );
    if (confirmDelete) {
      try {
        await makeRequest(Methods.DELETE, Routes.DELETE_USER);
        alert(language.successFullyDeleted);
      } catch (err) {
        alert("Something went wrong");
      } finally {
        await signOut();
      }
    }
  };

  if (isLoading) {
    return (
      <div className="productPage-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="settingsPage-container">
      <Container>
        {alert.show && (
          <Alert
            message={alert.message}
            variant="Danger"
            onClose={() => setAlert({ message: "", show: false })}
          />
        )}
        <Row>
          <Col
            lg={6}
            xl={7}
            className="d-none d-lg-flex justify-content-center align-items-center"
          >
            <Image
              src={require("../../4her-logo.png")}
              alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
              fluid
            />
          </Col>
          <Col lg={6} xl={5} className="mt-lg-0">
            <Row
              xs={4}
              md={4}
              className="align-items-center justify-content-center mb-4"
            >
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <Image
                  src={require("../../default-profile-avatar.png")}
                  roundedCircle
                  style={{ width: "200px", height: "200px" }}
                />
                {isEditingName ? (
                  <Form.Group controlId="newName" className="pt-2 pb-2">
                    <div className="name-input-group">
                      <Form.Control
                        type="text"
                        placeholder={language.newName}
                        value={newName}
                        onChange={(e) => {
                          handleNameChange(e.target.value, "newName");
                        }}
                        style={{
                          borderColor: "#c2aff0",
                        }}
                      />
                      <div
                        style={{
                          paddingLeft: "4px",
                        }}
                      >
                        {newName.length === 0 ||
                        (newName.length > 0 && newName.length < 4) ? (
                          <AiOutlineCheck size={32} />
                        ) : (
                          <AiOutlineCheck
                            size={32}
                            color="#198754"
                            className="settingsPage-submit-icon"
                            onClick={async () => {
                              await handleNewName();
                              setIsEditingName(false);
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          paddingLeft: "4px",
                        }}
                      >
                        <AiOutlineClose
                          size={32}
                          color="#BA1A1A"
                          className="settingsPage-close-icon"
                          onClick={() => {
                            setIsEditingName(false);
                            setNewName("");
                          }}
                        />
                      </div>
                    </div>
                    {newName.length > 0 && newName.length < 4 ? (
                      <div className="mt-2">
                        <Form.Text className="text-danger">
                          {language.nameMustBeLonger}
                        </Form.Text>
                      </div>
                    ) : null}
                  </Form.Group>
                ) : (
                  <span
                    className="d-flex align-items-center justify-content-center h-100 text-nowrap"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    {language.hello}, {user.name} !
                    <div
                      style={{
                        paddingLeft: "4px",
                      }}
                    >
                      <AiFillEdit
                        size={32}
                        color="#c2aff0"
                        className="settingsPage-edit-icon"
                        onClick={() => setIsEditingName(true)}
                      />
                    </div>
                  </span>
                )}
              </div>
            </Row>
            {user.facebookId ? null : (
              <div>
                <Form.Group controlId="password" className="pt-2 pb-2">
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#c2aff0" />
                    <Form.Control
                      type="password"
                      placeholder={language.oldPassword}
                      value={inputs.oldPassword}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "oldPassword");
                        validateInputs("oldPassword", e.target.value);
                      }}
                      style={{
                        backgroundColor: "#F7F0F5",
                        border: "none",
                      }}
                      className="settingsPage-formControl"
                    />
                  </div>
                  {!isValid.oldPassword && inputs.oldPassword.length > 0 && (
                    <div className="mt-2">
                      <Form.Text className="text-danger">
                        {language.passwordHelperText}
                      </Form.Text>
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="newPassword" className="pt-2 pb-2">
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#c2aff0" />
                    <Form.Control
                      type="password"
                      placeholder={language.newPassword}
                      value={inputs.password}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "password");
                        validateInputs("password", e.target.value);
                      }}
                      style={{
                        backgroundColor: "#F7F0F5",
                        border: "none",
                      }}
                      className="settingsPage-formControl"
                    />
                  </div>
                  {!isValid.password && inputs.password.length > 0 && (
                    <div className="mt-2">
                      <Form.Text className="text-danger">
                        {language.passwordHelperText}
                      </Form.Text>
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="retypeNewPassword" className="pt-2 pb-2">
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#c2aff0" />
                    <Form.Control
                      type="password"
                      placeholder={language.confirmPassword}
                      value={inputs.retypePassword}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "retypePassword");
                        validateInputs("retypePassword", e.target.value);
                      }}
                      style={{
                        backgroundColor: "#F7F0F5",
                        border: "none",
                      }}
                      className="settingsPage-formControl"
                    />
                  </div>
                  {!(inputs.password === inputs.retypePassword) &&
                    !!inputs.retypePassword.length && (
                      <div className="mt-2">
                        <Form.Text className="text-danger pb-1">
                          {language.pleaseMakeSureThePassMatch}
                        </Form.Text>
                      </div>
                    )}
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={async () => await handleSubmit()}
                  className="mt-2 w-100"
                  style={{
                    backgroundColor: "#99EDC3",
                    border: "none",
                    color: "#373737",
                    fontWeight: "bold",
                  }}
                  disabled={!(isValid.password && isValid.retypePassword)}
                >
                  {language.changePassword}
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => await handleDeleteAcc()}
                  className="mt-2 w-100"
                  style={{ backgroundColor: "#ba1a1a", border: "none" }}
                >
                  {language.deleteAccount}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingsPage;
