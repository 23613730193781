import React from "react";
import ReactMarkdown from "react-markdown";
import "./PrivacyPolicy.css";
import useLanguage from "../../hooks/useLanguage";

export default function PrivacyPolicy() {
  const { privacyPolicy } = useLanguage();
  return (
    <div className="privacyPolicyContainer">
      <ReactMarkdown children={privacyPolicy} />
    </div>
  );
}
