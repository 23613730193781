export const transformString = (numbers) => {
  const numDigits = numbers.length;

  if (numDigits <= 3) {
    return numbers;
  }

  const transformedString = "*".repeat(numDigits - 2) + numbers.slice(-2);
  return transformedString;
};
