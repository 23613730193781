import React from "react";
import { Col, Card } from "react-bootstrap";
import { AiFillHeart, AiOutlineHeart } from "../icons";
import "./CatalogItem.css";
import useLanguage from "../hooks/useLanguage";

const CatalogItem = ({
  product,
  onClick,
  disableFavourite = false,
  isFavourite = false,
  onFavClick,
}) => {
  const { language } = useLanguage();

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Col key={product.id}>
      <Card
        className="mb-4"
        style={{
          backgroundColor: "#F7F0F5",
          boxShadow: "2px 1px 11px 0 rgba(194, 175, 240, 1)",
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          zIndex: 1,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              zIndex: 1,
            }}
          ></div>
        )}
        <div style={{ height: "200px", overflow: "hidden" }}>
          <Card.Img
            alt={`Product ${product.id} - Category: ${product.title}`}
            variant="top"
            src={product.photos[0].link}
            className="img-fluid"
            style={{
              objectFit: "contain",
              height: "100%",
              borderBottom: "1px solid #c2aff0",
            }}
          />
        </div>
        {disableFavourite ? null : (
          <div
            style={{ position: "absolute", top: 5, right: 5, zIndex: 10 }}
            onClick={onFavClick}
          >
            {isFavourite ? (
              <AiFillHeart
                size={32}
                color="#c2aff0"
                className="catalogItem-fav-icon"
              />
            ) : (
              <AiOutlineHeart
                size={32}
                color="#c2aff0"
                className="catalogItem-fav-icon"
              />
            )}
          </div>
        )}
        <Card.Body>
          <Card.Title
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.title}
          </Card.Title>
          <Card.Text>
            {product.city}, <br />
            {product.createdAt.slice(0, 10)}
          </Card.Text>
          <Card.Text
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              zIndex: 10,
              fontSize: "18px",
            }}
          >
            <strong>
              {product.price}
              {language.bgn}
            </strong>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CatalogItem;
