import React, { useState, useRef, useEffect, useCallback } from "react";
import { Carousel, Container, Row, Col, Button, Badge } from "react-bootstrap";
import "./ProductPage.css";
import Image from "react-bootstrap/Image";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineClose,
  AiOutlineArrowDown,
} from "../../../icons";
import { BsFillTelephoneFill } from "../../../icons";
import ModalCarousel from "../../../components/ModalCarousel";
import { transformString } from "../../../utils/transformNumber";
import LoginToContactSeller from "../../../components/LoginToContactSeller";
import { useParams } from "react-router-dom";
import useProduct from "../../../hooks/useProduct";
import Spinner from "react-bootstrap/Spinner";
import useAuth from "../../../hooks/useAuth";
import DownloadAppModal from "../../../components/DownloadAppModal";
import useLanguage from "../../../hooks/useLanguage";

const ProductPage = () => {
  const { id } = useParams();
  const { addOrRemovFromFavourites, favourites, getProduct, getFavourites } =
    useProduct();
  const { user } = useAuth();
  const { language, selectedLanguage } = useLanguage();

  const [product, setProduct] = useState(null);
  const [productViews, setProductViews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [downloadAppModalShow, setDownloadAppModalShow] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const fetchData = useCallback(async () => {
    try {
      const productData = await getProduct(id);
      setProduct(productData.post);
      setProductViews(productData.viewCount);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = (index) => {
    setCarouselIndex(index);
    setModalShow(false);
  };

  const openDownloadAppModal = () => {
    setDownloadAppModalShow(true);
  };

  const closeDownloadAppModal = () => {
    setDownloadAppModalShow(false);
  };

  const toggleDescriptionExpand = () => {
    setDescriptionExpanded(!descriptionExpanded);
  };
  const [showExpandButton, setShowExpandButton] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isSmall =
        descriptionElement.offsetHeight >= descriptionElement.scrollHeight;
      setShowExpandButton(!isSmall);
    }
  }, [product]);

  useEffect(() => {
    if (user) {
      getFavourites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    setIsFavourite(
      favourites.length
        ? !!favourites.find((p) => p.id === parseInt(id))
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites]);

  const onFavouriteClick = (productId, isFavourite) => {
    addOrRemovFromFavourites(productId, isFavourite);
  };

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  if (isLoading) {
    return (
      <div className="productPage-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }
  if (isLoading && error) {
    return (
      <div className="productPage-container">
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="productPage-container">
      <Container className="product-container">
        <Row>
          <Col
            md={6}
            className="product-image-container product-carousel-col p-0"
            style={{
              backgroundColor: "#F7F0F5",
            }}
          >
            <Carousel
              activeIndex={carouselIndex}
              className="carousel-container"
              interval={null}
              variant="dark"
              style={{ cursor: "pointer" }}
              onSelect={handleSelect}
            >
              {product.photos.map((photo, index) => (
                <Carousel.Item key={index}>
                  <Image
                    src={photo.link}
                    alt={`Product ${index + 1} - Category: ${product.title}`}
                    className="productPage-carousel-image"
                    style={{ borderRadius: 5 }}
                    onClick={() => {
                      openModal();
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col md={6} className="product-details pt-2">
            <div className="w-100">
              <div className="product-text-container">
                <div>
                  <div>
                    {product.createdAt.slice(0, 10)}, {product.city}
                  </div>
                  <div>{product.title}</div>
                  <h3>
                    {product.price} {language.bgn}
                  </h3>
                </div>
                {user ? (
                  <div
                    className="productPage-fav-icon"
                    onClick={() => onFavouriteClick(id, isFavourite)}
                  >
                    {isFavourite ? (
                      <AiFillHeart size={48} color="#c2aff0" />
                    ) : (
                      <AiOutlineHeart size={48} color="#c2aff0" />
                    )}
                  </div>
                ) : null}
              </div>
              {product.filterValues.length ? (
                <div
                  className="product-text-container"
                  style={{
                    paddingBottom: "0.75rem",
                    paddingTop: "0.75rem",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  {product.filterValues.map((filter) => (
                    <Badge
                      key={"filter-key- " + filter.id}
                      pill
                      style={{
                        backgroundColor: "#c2aff0",
                        fontSize: "0.75rem",
                        padding: "8px 12px",
                        color: "#373737",
                      }}
                      bg="#c2aff0"
                      className="badge-lg"
                    >
                      {selectedLanguage === "bg" ? filter.bgName : filter.name}
                    </Badge>
                  ))}
                </div>
              ) : null}
              <div
                className="product-text-container"
                style={{ paddingBottom: "0.75rem", paddingTop: "0.75rem" }}
              >
                <div className={"description-container"}>
                  {language.description}: <br />
                  <div
                    ref={descriptionRef}
                    className={`description-text ${
                      descriptionExpanded ? "expanded" : ""
                    }`}
                  >
                    {product.description}
                  </div>
                  {showExpandButton ? (
                    descriptionExpanded ? (
                      <div className="close-icon">
                        <AiOutlineClose
                          size={24}
                          color="#c2aff0"
                          onClick={toggleDescriptionExpand}
                        />
                      </div>
                    ) : (
                      <div className="arrow-icon">
                        <AiOutlineArrowDown
                          size={24}
                          color="#c2aff0"
                          onClick={setDescriptionExpanded}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </div>
              <div
                className="product-text-container"
                style={{
                  paddingBottom: "0.75rem",
                  paddingTop: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div>
                  {language.seller}: <br />
                  <Image
                    src={require("../../../default-profile-avatar.png")}
                    roundedCircle
                    width={50}
                    height={50}
                  />{" "}
                  {product.author.name}
                </div>
                <div
                  className="pt-2 w-100"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    <BsFillTelephoneFill size={32} color="#c2aff0" />{" "}
                    {user
                      ? product.contactNumber
                      : transformString(product.contactNumber)}
                  </span>
                  {user ? (
                    <div className="pl-4">
                      <Button
                        style={{
                          backgroundColor: "#c2aff0",
                          borderColor: "#c2aff0",
                          color: "#373737",
                        }}
                        onClick={openDownloadAppModal}
                      >
                        {language.message}
                      </Button>
                    </div>
                  ) : (
                    <span className="pl-2">
                      <Button variant="secondary" disabled>
                        {language.message}
                      </Button>
                    </span>
                  )}
                </div>
              </div>
              <div
                className="product-text-container"
                style={{ paddingBottom: "0.75rem", paddingTop: "0.75rem" }}
              >
                <div>
                  {language.views}: {productViews}
                </div>
              </div>
              {user ? null : (
                <div
                  className="product-text-container"
                  style={{
                    paddingBottom: "0.75rem",
                    paddingTop: "0.75rem",
                    borderBottom: "none",
                  }}
                >
                  <LoginToContactSeller />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <ModalCarousel
        show={modalShow}
        handleClose={closeModal}
        carouselData={product.photos}
        index={carouselIndex}
      />
      <DownloadAppModal
        show={downloadAppModalShow}
        handleClose={closeDownloadAppModal}
        message={language.toUseTheFullFunc}
      />
    </div>
  );
};

export default ProductPage;
