import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsEnvelopeFill, BsFillLockFill, BsPencilSquare } from "../../../icons";
import "./ForgotPass.css";
import Alert from "../../../components/Alert";
import useAuth from "../../../hooks/useAuth";
import { Methods } from "../../../common/methods";
import { Routes } from "../../../common/routes";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../../hooks/useLanguage";

const ForgotPass = () => {
  const { makeRequest, ejectBearerInterceptor } = useAuth();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [isCodeSend, setIsCodeSend] = useState(false);

  const [inputs, setInputs] = React.useState({
    email: "",
    resetCode: "",
    password: "",
    retypePassword: "",
  });

  const [isValid, setIsValid] = React.useState({
    email: false,
    resetCode: false,
    pass: false,
    retypePass: false,
  });

  const resetInputs = () => {
    setInputs({
      email: "",
      resetCode: "",
      password: "",
      retypePassword: "",
    });
    setIsValid({
      email: false,
      resetCode: false,
      pass: false,
      retypePass: false,
    });
  };

  const validateInputs = (type, input) => {
    const passRegex = new RegExp(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    );
    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    const isEmpty = input.length > 0;

    if (type === "email") {
      setIsValid({
        email: emailRegex.test(input),
        resetCode: isValid.resetCode,
        pass: isValid.pass,
        retypePass: isValid.retypePass,
      });
    }
    if (type === "pass") {
      setIsValid({
        email: isValid.email,
        resetCode: isValid.resetCode,
        pass: passRegex.test(input),
        retypePass: isValid.retypePass,
      });
    }
    if (type === "retypePass") {
      setIsValid({
        email: isValid.email,
        resetCode: isValid.resetCode,
        pass: isValid.pass,
        retypePass: passRegex.test(input),
      });
    }
    if (type === "resetCode") {
      setIsValid({
        email: isValid.email,
        resetCode: isEmpty,
        pass: isValid.pass,
        retypePass: isValid.retypePass,
      });
    }
  };

  const handleOnChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const getResetCode = async () => {
    setIsCodeSend(true);
    await makeRequest(Methods.POST, Routes.FORGOT_PASS, {
      email: inputs.email,
    });
  };

  const resetPassword = async () => {
    await makeRequest(Methods.POST, Routes.FORGOT_PASS_RESET, {
      token: inputs.resetCode,
      email: inputs.email,
      password: inputs.password,
      confirmPassword: inputs.retypePassword,
    });
    ejectBearerInterceptor();
    resetInputs();
    navigate("/login");
  };

  return (
    <div className="forgotPage-container">
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col
            lg={6}
            xl={7}
            className="order-2 order-md-1 d-flex justify-content-center align-items-center"
          >
            <Image
              src={require("../../../4her-logo.png")}
              alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
              fluid
              className="forgotPage-logo"
            />
          </Col>
          <Col lg={6} xl={5} className="mt-md-0 order-1 order-md-2">
            {false ? (
              <Alert
                message={"error.message"}
                variant="Danger"
                onClose={() => {}}
              ></Alert>
            ) : null}
            <div className="d-flex justify-content-center align-items-center pb-2">
              {" "}
              <h3>{language.typeYourEmailToReceiveCode}</h3>
            </div>
            <Form>
              <Form.Group controlId="email" className="pb-2">
                <div className="icon-input-group">
                  <BsEnvelopeFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="email"
                    placeholder={language.enterEmail}
                    value={inputs.email}
                    onChange={(e) => {
                      handleOnChange(e.target.value, "email");
                      validateInputs("email", e.target.value);
                    }}
                    style={{ backgroundColor: "#F7F0F5", border: "none" }}
                    disabled={isCodeSend}
                    className="forgotPage-formControl"
                  />
                </div>
                {!isValid.email && inputs.email.length > 0 && (
                  <div className="mb-2">
                    <Form.Text className="text-danger pb-1">
                      {language.pleaseEnterValidEmail}
                    </Form.Text>
                  </div>
                )}
                {!isCodeSend ? (
                  <Button
                    variant="primary"
                    onClick={async () => await getResetCode()}
                    className="mt-2 w-100"
                    style={{
                      color: "#373737",
                      fontWeight: "bold",
                      backgroundColor: "#99EDC3",
                      border: "none",
                    }}
                    disabled={!isValid.email}
                  >
                    {language.sendCode}
                  </Button>
                ) : (
                  <div
                    style={{ fontWeight: "bold" }}
                    className="forgotPage-text-center mt-2"
                  >
                    {language.youDidntReceiveCode}
                    <span
                      onClick={async () => await getResetCode()}
                      style={{
                        color: "#C2AFF0",
                        border: "none",
                        fontWeight: "bold",
                      }}
                      className="link-span"
                    >
                      {language.sendAgain}
                    </span>
                  </div>
                )}
              </Form.Group>
              {isCodeSend ? (
                <Form.Group className="pb-2">
                  <div className="icon-input-group">
                    <BsPencilSquare className="input-icon" color="#C2AFF0" />
                    <Form.Control
                      type="text"
                      id="code"
                      placeholder={language.enterCode}
                      value={inputs.resetCode}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "resetCode");
                        validateInputs("resetCode", e.target.value);
                      }}
                      style={{ backgroundColor: "#F7F0F5", border: "none" }}
                      className="forgotPage-formControl mb-2"
                    />
                  </div>
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#C2AFF0" />
                    <Form.Control
                      type="password"
                      id="password"
                      placeholder={language.enterPassword}
                      value={inputs.password}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "password");
                        validateInputs("pass", e.target.value);
                      }}
                      style={{ backgroundColor: "#F7F0F5", border: "none" }}
                      className="forgotPage-formControl mb-2"
                    />
                  </div>
                  {!isValid.pass && inputs.password.length > 0 && (
                    <div className="mb-2">
                      <Form.Text className="text-danger pb-1">
                        {language.passwordMustContain}
                      </Form.Text>
                    </div>
                  )}
                  <div className="icon-input-group">
                    <BsFillLockFill className="input-icon" color="#C2AFF0" />
                    <Form.Control
                      type="password"
                      id="retypePassword"
                      placeholder={language.enterRepeatPass}
                      value={inputs.retypePassword}
                      onChange={(e) => {
                        handleOnChange(e.target.value, "retypePassword");
                        validateInputs("retypePass", e.target.value);
                      }}
                      style={{ backgroundColor: "#F7F0F5", border: "none" }}
                      className="forgotPage-formControl mb-2"
                    />
                  </div>
                  {!(inputs.password === inputs.retypePassword) &&
                    !!inputs.retypePassword.length && (
                      <div className="mb-2">
                        <Form.Text className="text-danger pb-1">
                          {language.pleaseMakeSureThePassMatch}
                        </Form.Text>
                      </div>
                    )}
                  <Button
                    variant="primary"
                    onClick={async () => await resetPassword()}
                    className="mt-2 w-100"
                    style={{
                      color: "#373737",
                      fontWeight: "bold",
                      backgroundColor: "#99EDC3",
                      border: "none",
                    }}
                    disabled={
                      !(isValid.resetCode && isValid.pass && isValid.retypePass)
                    }
                  >
                    {language.resetPassword}
                  </Button>
                </Form.Group>
              ) : null}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPass;
