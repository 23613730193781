import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LanguageProvider } from "./hooks/useLanguage";
import { BrowserRouter as Router } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./hooks/useAuth";
import { ProductProvider } from "./hooks/useProduct";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AuthProvider>
      <LanguageProvider>
        <ProductProvider>
          <Router>
            <App />
          </Router>
        </ProductProvider>
      </LanguageProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
