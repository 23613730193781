import React, { useState, useRef, useEffect, useCallback } from "react";
import { Carousel, Container, Row, Col, Button, Badge } from "react-bootstrap";
import "./MyProductPage.css";
import Image from "react-bootstrap/Image";
import {
  AiOutlineClose,
  AiOutlineArrowDown,
  AiFillDelete,
} from "../../../icons";
import ModalCarousel from "../../../components/ModalCarousel";
import { useParams, useNavigate } from "react-router-dom";
import useProduct from "../../../hooks/useProduct";
import Spinner from "react-bootstrap/Spinner";
import DeleteAlert from "../../../components/DeleteAlert";
import useAuth from "../../../hooks/useAuth";
import { Methods } from "../../../common/methods";
import { Routes } from "../../../common/routes";
import Alert from "../../../components/Alert";
import useLanguage from "../../../hooks/useLanguage";

const MyProductPage = () => {
  //TODO fix carousel indexes
  const { id } = useParams();
  const { getProduct } = useProduct();
  const { makeRequest, user } = useAuth();
  const navigate = useNavigate();
  const { language, selectedLanguage } = useLanguage();

  const [product, setProduct] = useState(null);
  const [productViews, setProductViews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ message: "", isError: false });
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const productData = await getProduct(id);
      setProduct(productData.post);
      setProductViews(productData.viewCount);
    } catch (err) {
      setError({
        message: err?.message ? err?.message : "An error has occured",
        isError: true,
      });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (product && user.email !== product.author.email) {
      navigate("/myProducts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = (index) => {
    setCarouselIndex(index);
    setModalShow(false);
  };

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  const toggleDescriptionExpand = () => {
    setDescriptionExpanded(!descriptionExpanded);
  };
  const [showExpandButton, setShowExpandButton] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isSmall =
        descriptionElement.offsetHeight >= descriptionElement.scrollHeight;
      setShowExpandButton(!isSmall);
    }
  }, [product]);

  const handlePostDelete = async () => {
    try {
      setIsLoading(true);
      await makeRequest(Methods.DELETE, Routes.POSTS + "/" + product.id, {});
      await makeRequest(Methods.POST, Routes.ARCHIVE_CHATS + "/" + product.id);
    } catch (err) {
      setError({
        message: err?.message ? err?.message : "An error has occured",
        isError: true,
      });
    } finally {
      setIsLoading(false);
      setShowToast(false);
      navigate("/myProducts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if (isLoading) {
    return (
      <div className="myProductPage-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }
  if (isLoading && error.isError) {
    return (
      <div className="myProductPage-container">
        <span>{error.message}</span>
      </div>
    );
  }

  if (!product) {
    navigate("/myProducts");
    return null;
  }

  return (
    <div className="myProductPage-container">
      <Container className="myProduct-container">
        <Row>
          <Col
            md={6}
            className="myProduct-image-container myProduct-carousel-col p-0"
            style={{
              backgroundColor: "#f4f4f4",
            }}
          >
            <Carousel
              activeIndex={carouselIndex}
              className="myProduct-carousel-container"
              interval={null}
              variant="dark"
              style={{ cursor: "pointer" }}
              onSelect={handleSelect}
            >
              {product.photos.map((photo, index) => (
                <Carousel.Item key={index}>
                  <Image
                    src={photo.link}
                    alt={`Product ${index + 1} - Category: ${product.title}`}
                    className="myProduct-carousel-image"
                    style={{ borderRadius: 5 }}
                    onClick={() => openModal()}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col md={6} className="myProduct-details pt-2">
            {error.isError ? (
              <Alert
                message={error.message}
                variant="Danger"
                onClose={() => setError({ message: "", isError: false })}
              ></Alert>
            ) : null}
            <div className="w-100">
              <div className="myProduct-text-container">
                <div>
                  <div>
                    {product.createdAt.slice(0, 10)}, {product.city}
                  </div>
                  <div>{product.title}</div>
                  <h3>
                    {product.price} {language.bgn}
                  </h3>
                </div>
              </div>
              {product.filterValues.length ? (
                <div
                  className="product-text-container"
                  style={{
                    paddingBottom: "0.75rem",
                    paddingTop: "0.75rem",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  {product.filterValues.map((filter) => (
                    <Badge
                      key={"filter-key- " + filter.id}
                      pill
                      style={{
                        backgroundColor: "#c2aff0",
                        fontSize: "0.75rem",
                        padding: "8px 12px",
                        color: "#373737",
                      }}
                      bg="#c2aff0"
                      className="badge-lg"
                    >
                      {selectedLanguage === "bg" ? filter.bgName : filter.name}
                    </Badge>
                  ))}
                </div>
              ) : null}
              <div
                className="myProduct-text-container"
                style={{ paddingBottom: "0.75rem", paddingTop: "0.75rem" }}
              >
                <div className={"myProduct-description-container"}>
                  {language.description}: <br />
                  <div
                    ref={descriptionRef}
                    className={`myProduct-description-text ${
                      descriptionExpanded ? "expanded" : ""
                    }`}
                  >
                    {product.description}
                  </div>
                  {showExpandButton ? (
                    descriptionExpanded ? (
                      <div className="close-icon">
                        <AiOutlineClose
                          size={24}
                          color="#D391B7"
                          onClick={toggleDescriptionExpand}
                        />
                      </div>
                    ) : (
                      <div className="arrow-icon">
                        <AiOutlineArrowDown
                          size={24}
                          color="#D391B7"
                          onClick={setDescriptionExpanded}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </div>

              <div
                className="myProduct-text-container"
                style={{ paddingBottom: "0.75rem", paddingTop: "0.75rem" }}
              >
                <div>
                  {language.views}: {productViews}
                </div>
              </div>
              <div
                className="myProduct-text-container pt-2 w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "none",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#ba1a1a",
                    borderColor: "#ba1a1a",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={() => setShowToast(true)}
                >
                  <AiFillDelete />
                  <div style={{ paddingLeft: "4px" }}> {language.delete} </div>
                </Button>
              </div>
              {showToast ? (
                <div
                  className="pt-2"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <DeleteAlert
                    variant="Danger"
                    onClose={() => setShowToast(false)}
                    handleCancel={() => setShowToast(false)}
                    handleDelete={() => handlePostDelete()}
                  ></DeleteAlert>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      <ModalCarousel
        show={modalShow}
        handleClose={closeModal}
        carouselData={product.photos}
        index={carouselIndex}
      />
    </div>
  );
};

export default MyProductPage;
