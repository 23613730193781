export const privacyPolicyBg = `**Политика за поверителност на „4Хър" ООД**

Този документ („**Политиката/Политика за поверителност**") има за цел да информира нашите клиенти - **физически лица**, съответно представителите на клиенти - юридически лица, или лица, посочени за контакт във връзка с изпълнение на договор с клиент (работници и служители на юридическо лице клиент), относно това как „4Хър" ООД *(„ние", „нас", „наши", „Дружество/то"*), при осъществяване на дейността си, използва или споделя личните данни, които Вие предоставяте, или които по друг начин сме получили или създали и които са свързани с Вас. Желателно е да отделите време, за да се запознаете с тази Политика за поверителност, както и периодично да я преглеждате като натиснете бутон „Политика за поверителност" в нашата Платформа, защото може да я променим в даден момент.

Когато се обръщате към нас, за да сключите договор за предлаганите от нас услуги, или по друг повод, **Вие ни предоставяте определена информация за себе си -** като например имена, адрес.

Съгласно **законодателството**, *в това число Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/ЕО, в сила от 25 май 2018 година („Регламент/ът", „ОРЗД"*), **тази информация за Вас представлява Ваши лични данни**, а Вие сте техен субект. От своя страна, ние се явяваме **Администратор** на Вашите лични данни. Данните, които ни идентифицират като Администратор и начините за връзка с Нас, са посочени в края на този документ.

По-долу в тази Политика ще получите отговор на следните въпроси:

-   **КАКВИ ЛИЧНИ ДАННИ СЪБИРАМЕ?**
-   **КАК СЪБИРАМЕ И ИЗПОЛЗВАМЕ ВАШИТЕ ЛИЧНИ ДАННИ?**
-   **С КОГО СПОДЕЛЯМЕ ВАШИТЕ ЛИЧНИ ДАННИ?**
-   **ПРЕДАВААМЕ ЛИ ЛИЧНИ ДАННИ ИЗВЪН БЪЛГАРИЯ?**
-   **КОЛКО ВРЕМЕ СЪХРАНЯВАМЕ ЛИЧНИТЕ ВИ ДАННИ?**
-   **КОЛКО ЗАЩИТЕНИ СА ЛИЧНИТЕ ВИ ДАННИ?**
-   **КАКВИ ПРАВА ИМАТЕ?**
-   **КАКВО Е ВАЖНО ДА ЗНАЕТЕ ПРИ УПРАЖНЯВАНЕ НА ПРАВАТА ВИ?**
-   **КАК ЩЕ ВИ УВЕДОМИМ ЗА ПРОМЕНИ В СЪДЪРЖАНИЕТО В ТАЗИ ПОЛИТИКА ЗА ПОВРИТЕЛНОСТ?**
-   **КАК ДА СЕ СВЪРЖЕТЕ С НАС -- КОИ СМЕ НИЕ?**

Когато се обръщате към нас чрез Платформата ни или чрез интернет страницата ни, за да създадете профил и да използвате услугите ни, т.е. за да сключите договор за предоставяне на услуги или да получите информация, да зададете въпроси или заявите Ваши права, ние може да получим директно от Вас или да Ви помолим да ни предоставите определена информация за себе си.

Ваши лични данни, които събираме във връзка със сключването и изпълнението на сключени с Вас договори за услуги, са определени в приложимите нормативни актове. 

Личните данни, които събираме, могат да включват:

1.  *Данни относно физическа идентичност* - три имена
2.  *Информация за контакт* - имейл адрес, телефонен номер;
3.  *Данни относно професионална дейност* *--* позиция или длъжност в организацията, за която работите; Например лични данни относно позицията Ви в  представляваното от Вас дружество и наличие на представителна власт/упълномощаване за създаване на профил в Платформата ни;

Когато обработваме личните Ви данни за целите на основната ни дейност, както и за да изпълним нормативните си задължения, тази обработка е задължителна, за изпълнение на тези цели. Без тези данни, не бихме могли да извършваме нашата дейност. Така например, ако не ни предоставите Вашите имена и имейл адрес, алтернативно ако не използвате логване с профил от социална мрежа ние не бихме могли да сключим с Вас договор за създаване на потребителски профил в Платформата ни или да Ви предоставим необходимата информация по неговото изпълнение. В други случаи, при събирането на Ваши лични данни ние ще Ви уведомим дали предоставянето на данни е необходимо и какви са последствията, ако откажете.

**Как събираме и използваме Вашите лични данни?**

Ние обработваме Вашите лични данни, въз основа следните ***правни основания:***

-   *Във връзка със създаване на Вашия потребителски профил, т.е. за сключване и изпълнение на договор за предлагани от нас услуги**,* който сключваме с Вас; или
-   *За изпълнение на наши задължения**,* които следват от нормативен акт, например във връзка със задължения, предвидени в Закона за счетоводството и Данъчно-осигурителния процесуален кодекс или при предоставяне на информация на съдебните и други държавни органи; или
-   *Наш или на трето лице легитимен интерес**,* освен когато пред него преимущество имат Ваши интереси или Ваши основни права и свободи. Примери за наш легитимен интерес са установяване, упражняване или защита на правни претенции; киберсигурност и защита срещу хакерски атаки и подобряване дейността и качеството на услугите ни;
-   *Вашето изрично информирано съгласие* в някои случаи, например за целите на директния маркетинг или за предоставяне на чувствителни лични данни или за изпращане от Ваша страна на запитвания, искания и оплаквания, освен ако не е налице друго основание за това. Предоставеното Ваше съгласие може да бъде оттеглено по всяко време като ни пишете на адресите и имейла за връзка с нас, посочени в края на този документ, при което ние ще преустановим занапред да обработваме Вашите данни, предмет на оттегленото съгласие.

Ние използваме Вашите лични данни за една или повече от долу изброените ***цели:***

1.  Да изпълним нашите задължения по изпълнението на договора, сключен с Вас, т.е. във връзка с обслужван на потребителския Ви профил;
2.  Да осъществяваме връзка с Вас, когато сме/сте Ви/ни задали въпрос или сме/сте Ви/ни отправили оплакване или искане за оферта за услуга, или да Ви уведомяваме за промени в работата ни, или за пробив в сигурността на данните; да Ви изпращаме съобщения по електронен път за наши продукти и услуги, когато сте наш клиент (директен маркетинг);
3.  Да спазваме правни, регулаторни и други изисквания (като например в областта на данъчното право и счетоводството), да извършваме дейността си съобразно приложимото законодателство и професионалните норми и правила, да отговаряме на искания на местни или чужди регулаторни, държавни или съдебни органи;
4.  Да подобряваме услугите, които предоставяме, включително за вътрешни цели като одити, анализи и проучвания, които да ни помогнат да подобрим дейността си или за да наблюдаваме и анализираме тенденциите и използването на услугите ни;
5.  Във връзка с *правни претенции,* за да защитаваме интересите си в съдебни спорове или други производства, образувани от компетентните органи, както и за цели, посочени на други места в това Уведомление.

**С кого споделяме Вашите лични данни?**

При осъществяване на дейността ни се налага да споделяме Вашите лични данни с някои от следните лица:

-   Наши доставчици на услуги (за целите на предоставянето и поддръжката на информационните системи и гарантиране на информационната сигурност и др.)
-   Нашите адвокати, професионални консултанти и одитори;
-   Регулаторни, държавни и съдебни органи, които по силата на нормативен акт имат правомощия да изискват предоставянето на информация, сред която и лични данни, като например - съдилища, прокуратура, различни органи с правомощия по защита на националната сигурност и обществен ред, и др.

Във всички случаи ние сключваме договори в писмена форма с доставчиците на услуги, с които работим, като изискваме от тях да предприемат необходимите мерки, за да гарантират защитата на Вашите лични данни. Ние ще предоставим на тези наши контрагенти единствено информацията, която им е необходима, за да за ни предоставят договорените услуги, без да им разрешаваме да използват Вашата информация за техни собствени цели. Ние няма да предоставяме Ваши лични данни на трети лица, за да Ви пращат непоискани маркетингови съобщения, освен ако Вие сте дали необходимото изрично съгласие за това. Моля да имате предвид, че не сме отговорни за дейността на всякакви трети страни във връзка с обработката на личните Ви данни.

**Предаване на лични данни извън Република България**

Ние не предаваме Вашите лични данни на трети дружества извън ЕС и ЕИП.

Във всеки случай на евентуален бъдещ трансфер на лични данни, „4Хър" ООД гарантира сигурността и поверителността на Вашите лични данни чрез прилагането на всички необходими технически и организационни мерки за защита на личните данни.

Вашите лични данни ще се трансферират при условията на подписани Стандартни договорни клаузи, утвърдени от Европейската комисия. 

**Колко време съхраняваме личните Ви данни?**

Ние имаме вътрешна политика, която определя колко дълго съхраняваме личните Ви данни. Тя е изградена на базата на ***(а)*** вида на информацията, която събираме, и ***(б)*** целите, за които я събираме.

Принципно, ние съхраняваме Вашите лични данни толкова дълго, колкото е необходимо за целите на обработване, за които данните са били събрани и за всяка друга допустима и свързана цел, или до изтичане на законово определен срок.

Когато обработваме Ваши лични данни за целите на директния маркетинг въз основа на Вашето изрично, информирано писмено съгласие, ние ограничаваме съхранението на данните до 2 (две) години от момента на получаването на съответното съгласие.

Наш легитимен интерес е да запазим определена Ваша лична информация до изтичане на давностния срок за предявяване на претенции (5 години) след изтичане или прекратяване на договора с Вас. Ние няма да изтрием или анонимизираме Ваши лични данни, ако те са необходими за висящо съдебно или административно производство или производство по разглеждане на Ваша жалба срещу нас.

**Колко защитени са личните Ви данни?**

Ние ценим високо поверителността на личните Ви данни и се отнасяме много сериозно към мерките за сигурност на личните данни, които сме събрали и съхраняваме.

Ние използваме множество технически и организационни мерки, подходящи с оглед на чувствителността на информацията, която поддържаме, за да защитим личните данни, които ни предоставете от непозволен достъп, използване или разкриване. Например ние кодираме комуникациите посредством SSL криптирана връзка, имаме защитни стени (firewalls) и антивирусни програми, средства за контролиране на достъпа, разделяне на задълженията, криптографска защита, защита чрез пароли и идентификация при пренасянето на информация и други. 

Приели сме политики и процедури, регламентирани със Заповеди на управителя на дружеството, като същевременно сме определили лица по защита на данните, регистрите, които ще се поддържат, включително на хартиен носител, достъпа до тях, сроковете за съхранение на обработваните лични данни, процедурите за тяхното унищожаване. 

Ние изискваме от нашите доставчици и партньори, които имат достъп до Вашите лични данни, да използват подходящи мерки, с които да осигурят защитата и поверителността на личните Ви данни. Вие обаче също носите отговорност за опазване на Вашата лична информация, която споделяте с нас през интернет. За съжаление предаването на информация по интернет или по телефон може да не е напълно сигурно, въпреки взетите от нас мерки, предвид преминаването на същата през мрежи, канали и платформи на трети доставчици на електронни услуги. Поради това, моля да имате предвид, че предаването на Ваша лична информация чрез интернет или по телефон, се извършва на Ваш риск.

**Какви права имате?**

Във връзка с Вашите лични данни, Вие имате определени права спрямо нас, които са Ви предоставени от ОРЗД (GDPR) и останалото приложимо законодателство. Понякога определени права могат да възникнат и да бъдат упражнени само при определени основания за обработване на личните Ви данни; други ваши права търпят известни ограничения и изключения по силата на законодателството. За да упражните правата си или да зададете въпроси, следва да отправите искането си на посочените най-долу имейл или адрес за връзка с нас.

Съгласно приложимото законодателство **Вие имате следните права:**

1.  Право на **достъп** до личните данни, свързани с Вас;
2.  Правото да поискате **коригиране** на неточните личните данни, свързани с Вас;
3.  Право да поискате **ограничаване на обработването** на личните данни, свързани с Вас;
4.  Право да **възразите срещу обработването** на личните Ви данни;
5.  Правото да поискате **изтриване** на личните данни свързани с Вас („*правото да бъдеш забравен*")*;*
6.  Правото да **получите** личните данни, които сте ни предоставили и които Ви засягат, и да ги използвате отново като ги **прехвърлите** на друг администратор („*право на преносимост*");
7.  Право **да подадете жалба** до компетентния надзорен орган или до съда, в случай че са нарушени правата Ви или сте пострадали от незаконосъобразно обработване на личните Ви данни. Компетентният орган по защита на лични данни в Република България е **Комисията за защита на личните данни**, с адрес в гр. София, бул. „Цветан Лазаров" № 2, с електронна поща: <kzld@cpdp.bg> и интернет-страница: [www.cpdp.bg](http://www.cpdp.bg);
8.  Когато обработването се основава на дадено от Вас съгласие, имате **право да оттеглите съгласието** си за обработване на личните Ви данни по всяко време, като ни пишете на посочените в края на документа адреси и имейл за връзка с нас, без това да засяга законосъобразността на обработването въз основа на съгласието Ви преди то да бъде оттеглено.

Вие имате право на **достъп** и можете да поискате по-подробна информация за това дали обработваме Ваши лични данни, какви категории, за какви цели, на кого ги разкриваме и др. Ако сте пожелали, ще Ви осигурим достъп до Вашите лични данни, които са в процес на обработване, под формата на тяхно **копие.** Копието е **безплатно** за Вас. Ако поискате допълнителни копия или индивидуално форматирана или по-детайлна информация или непропорционално (злоупотребявайки) упражнявате правата си, можем да Ви определим разумна такса, която да покрие административните ни разходи за изготвянето им. Ако сте подали искането с електронни средства, по възможност ще Ви предоставим информацията в широко използвана електронна форма, освен ако не сте поискали друго от нас.

Ако не обработваме Ваши лични данни, ще Ви уведомим за това. Ако отхвърлим искането Ви за копие от данните, ще изложим пред Вас причините за това решение.

Упражняването на **правото Ви на достъп** не следва да влияе неблагоприятно върху правата или свободите на други лица, включително върху търговската тайна или интелектуалната собственост, и по-специално върху авторското право за защита на софтуера. В случай, че преценим, че има основание да се очаква подобно отрицателно въздействие, можем мотивирано да ограничим част от информацията, която ще Ви предоставим, така че да не се стигне до него.

**Ако обработваме голямо количество информация за Вас, можем да Ви помолим да посочите точно информацията или дейностите по обработването, за които се отнася искането Ви**. Така ще ни помогнете да се ориентираме по-добре и по-бързо, а и самите Вие по-скоро да получите нужните Ви данни.

Ако сте **възразили срещу обработването** на личните Ви данни, основано на наш **легитимен интерес** или легитимен интерес на трета страна, ние можем да продължим обработването въпреки Вашето възражение, ако можем да докажем, че съществуват убедителни законови основания за обработването, които имат предимство пред Вашите интереси, права и свободи, или за установяването, упражняването или защитата на правни претенции. В случай, че отнасящи се до Вас данни се използват за целите на ***директния маркетинг,*** имате право безплатно и по всяко време да възразите срещу обработването им за тази цел и ние ще прекратим обработването им, като ни уведомите писмено на адресите, посочени в края на това Уведомление в раздел *Как да се свържете с нас*.

Когато искате да **коригираме** Ваши лични данни може да поискате да уведомим и третите лица, на които са разкрити, с изключение на случаите когато това е невъзможно или е свързано с прекомерни усилия.

Вие имате право да поискате **изтриване** на личните Ви данни, когато те повече не са необходими за целите, за които са били събрани или обработвани по друг начин; когато оттегляте съгласието си за обработване на личните Ви данни и няма друго правно основание за обработването; когато възразите срещу обработване основано на легитимен интерес и той няма преимущество пред Вашите права, свободи и интереси; при обработване без правно основание или изтриването на личните Ви данни е наше правно задължение, определено с правото на Република България или Европейския съюз. По силата на действащото законодателство, имаме **право да продължим обработването въпреки Вашето искане за изтриване**,за да спазим нашите правни задължения, предвидени в правото на Република България или Европейския съюз, които изискват обработване на личните Ви данни или когато е необходимо за установяването, упражняването или защитата на правни претенции.

За да упражните **правото на ограничаване на обработването,** трябва да са изпълнени следните условия:

-   Оспорвате **точността** на личните данни;
-   Обработването е **без правно основание,** но вместо да искате ние да ги изтрием, Вие изисквате ограничаване на използването им;
-   Ние не се нуждаем повече от личните данни за целите на обработването, но Вие ги **изисквате** за установяването, упражняването или защитата на правни претенции;
-   **Възразили** сте срещу обработването на основание ***„легитимен интерес".***

Когато обработването на личните Ви данни е било ограничено, все пак бихме могли да продължим обработването им в два случая:

-   **с** изрично Ваше **съгласие;** или
-   за установяването, упражняването или защитата на **правни претенции или за защита на правата на друго физическо лице** или поради важни основания от **обществен интерес** за Съюза или държава-членка.

Правото на преносимост съществува и може да се упражни, само когато са изпълнени следните две условия: ***(1)*** става въпрос за обработване по автоматизиран начин (т.е. това право не се отнася до обработване на данни под формата на хартиени досиета) и ***(2)*** освен че става по автоматизиран начин, обработването на Вашите лични данни се извършва въз основа на ***(а)*** Вашето съгласие или ***(б)*** на **договор, по** който сте страна **или** за **предприемане на стъпки** по Ваше искане преди сключването на договор. Вие имате право да получите личните си данни в структуриран, широко използван и пригоден за машинно четене формат или да поискате пряко прехвърляне на личните Ви данни към друг администратор, когато това е технически осъществимо.

Трябва да знаете, че когато упражните **правото на преносимост,** това **не** води до заличаването на Вашите данни от нашите системи. Вие ще можете да продължите да се ползвате от услугите ни дори след операцията по преносимост на данните. Преносимостта на данните също така **не** засяга първоначалния период на запазване, който се отнася за предадените данни. Можете да упражнявате своите останали права, които са посочени в законодателството и сме изброили тук, докато Ние продължаваме да обработваме данните.

Ако считате, че ние нарушаваме приложимата нормативна уредба, молим да се свържете с нас за изясняване на въпроса. Разбира се, имате право и незабавно да подадете жалба до надзорен орган в Европейския съюз, където живеете, работите, или където се е осъществило твърдяното нарушение на Вашите права. **Надзорният орган по защита на личните данни в България е Комисията за защита на личните данни***,* ***с адрес*** *гр. София, п.к. 1592, бул. „Цветан Лазаров" № 2,* ***електронна поща*** *:* [*kzld@cpdp.bg*](mailto:kzld@cpdp.bg) ***интернет страница****:* [*www.cpdp.bg*](http://www.cpdp.bg)*,* ***телефон за контакт****: 02/91-53-519, 02/91-53-555.* Можете да потърсите защита на Вашите права и по съдебен ред.

**Какво е важно да знаете при упражняването на правата Ви?**

**Как да заявим упражняване на правата си****?** За да упражните правата си съгласно Регламента можете да ни пишете на електронна поща или да ни пратите писмо по пощата на данните за контакт, посочени най-долу.

Заявления във връзка с упражняване на Ваши права по принцип се подават лично или от изрично упълномощено от Вас лице. Когато са налице нормативни процедурни правила във връзка с упражняване на правата Ви (в Регламента, Закона за защита на личните данни и други актове) те също следва да бъдат спазени.

**В каква форма ще Ви отговорим?** Във формата, в която се отправили запитването си до нас -- писмено на хартиен носител или в електронна форма. Когато сте подали искане с електронни средства, по възможност информацията ще Ви бъде предоставена в широко използвана електронна форма, освен ако не сте поискали друго.

**В какъв срок ще получите отговор?** До **един месец** от получаване на Вашето искане ще Ви предоставим информация относно действията, които сме предприели по него. **При необходимост** този срок **може да бъде удължен с още два месеца,** като се взема предвид сложността и броя на исканията. Ако такова удължаване се наложи, ще Ви уведомим в срок до един месец от подаване на искането Ви, като ще Ви обясним и по какви причини се налага това удължаване.

**Съмнения относно Вашата самоличност****.** Когато имаме основателни опасения във връзка със самоличността на физическото лице, което подава искането до нас, можем да поискаме предоставянето на допълнителна информация, необходима за потвърждаване на самоличността на субекта на данните. Ако не получим такава информация и не сме в състояние да идентифицираме субекта на данните, можем да откажем да предприемем действия въз основа на отправено до нас искане за упражняване на някое от посочените в тази Политика права.

Когато някой гражданин отправя към нас искания, които са явно неоснователни или прекомерни, по-специално поради своята повторяемост, ние имаме правото или: ***а)*** да наложим разумна такса, като вземем предвид административните разходи за предоставяне на информацията или комуникацията или предприемането на исканите действия, или ***б)*** да откажем да предприемем действия по искането.

**Как ще Ви уведомим за промени в съдържанието на тази Политика?** 

Ние можем да актуализираме периодично информацията в тази Политика, в отговор на развитието на нашия бизнес и променящите се правни изисквания. Ние ще Ви уведомим, в случай че бихме желали или се налага да използваме Вашите лични данни за цели и по начин, значително различаващи се от това, за което сме Ви информирали и при необходимост ще потърсим Вашето съгласие.

Ние ще Ви уведомим за промени по същество в този документ като поставим банер - информационно съобщение при вход в потребителския Ви профил в нашата Платформа.

**Как да се свържете с нас?**

За да упражните някои от Вашите права съгласно приложимото законодателство в областта на защитата на личните данни или за да се свържете с нас в случай че имате някакви въпроси, засягащи настоящия документи, можете да се обърнете към нас чрез някой от посочените по-долу форми за контакт:

**Лице по защита на личните данни** - Румяна Йорданова, с **адрес за кореспонденция:** гр. София, ул. Тинтява 13Б, ет. 6, имейл адрес: [**rumyana.yordanova@penkov-markov.eu**](mailto:rumyana.yordanova@penkov-markov.eu)

Администратор на лични данни е „*4Хър" ООД*, със седалище и адрес на управление: гр. София, ул. Лавеле 19, ет. 4, офис 5.`;
