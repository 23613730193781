export const termsOfUseEn = `# General Terms and Conditions

# 1. General Terms and Conditions ("GTC")

**1.1.** 4Her is an electronic platform (hereinafter referred to as the "Platform") accessible via mobile devices and computers in the form of a mobile application. The said application can also be downloaded from a link after loading the website - www.4her-bg.com. The Platform is freely used by users to post advertisements for the purpose of selling goods and services, including the conclusion of purchase contracts with traders who have published advertisements on the 4Her Platform, in compliance with the rules for posting advertisements and the relevant restrictions thereon described below in these GTC.

**1.2.** By visiting the Platform, you agree to the GTC of use of the Platform. By continuing your stay on the Platform you declare that you agree to and understand our GTC.

For the avoidance of doubt, we specify that each access to the Platform means that you have read the GTC of use and have agreed to abide by them unconditionally.

**1.3.** The GTC of 4Her are binding for all visitors of the Platform.

**1.4.** These GTC have the binding and mandatory effect of a contractual agreement applicable to all versions and devices from which our Platform Services are accessible.

**1.5.** The terms of use of the Platform, including the rules for registration, publishing of advertisements and ordering of items, as well as matters relating to the payment and complaints procedure, are set out below in these GTC. Everyone using the Platform is required to familiarise themselves with the contents of **this document**.

**1.6.** Guests may use the Platform with limited functions, provided that they comply with the provisions set out in these GTC, in compliance with the law and the principles of good faith.

**1.7.** **4Her is not a party to the Transactions**, has no control over any element of the Transactions and is not liable to any party in connection with any Transactions made through the Platform, i.e. in the event that you order an item from an advertisement published on our Platform, the Seller posting the advertisement is solely responsible for the quality, delivery, any defects, etc. of the item.

# 2. Platform administrator details - 4her

**2.1. 4Her Ltd** is a company with registered seat and address in the city of Sofia, 19 Lavele Str., floor 4, office 5, UIC 207248527.

**2.2.** **4Her Ltd** administrates the 4her Platform, in the form of the 4her-bg.com website, as well as the 4her mobile application (each of which is referred to hereinafter as the "Platform").

**2.3.** 4her can be contacted at the above address or by email at info@4her-bg.com

# 3. Definitions

**4Her** (Administrator/Platform) - see point 2. above.

**Guest** - a natural person using the Platform without having a User account on the Platform or, although having a User account, using the Platform without being logged in.

**Visitor** - any natural person who has accessed the Platform, whether as a guest or registered user.

**Account** (Profile) - a set of data with which a registered user on the Platform is associated, including information provided by the user on the Platform. The rules relevant to the Profile are set out below in these GTC.

**Delivery** - courier service for transportation of goods, which is requested by the user and for which the Platform is not liable.

**Day** - means 24 consecutive hours.

**Category** - a thematic category (e.g. Clothes, Shoes, Cosmetics) to which an advertisement is assigned. Each category can be divided into thematically related subcategories or filters.

**Buyer** - a user who enters into a transaction with a Seller using the Platform.

**Advertisement** - a proposal to sell or provide an Item made by a Seller that meets the requirements set out in Section 8 of these GTC.

**Item** - good or service subject to an Advertisement.

**General Terms and Conditions** ("GTC") - these General Terms and Conditions and the attachments thereto, setting out the rules for use of the Platform. The current version of the General Terms and Conditions is available on the Website at any time, in a form that allows downloading, saving on a durable medium or printing them, as well as in the mobile application, when initially logging into a Profile, a window will pop up with the option to open the General Terms and Conditions, and subsequently, after registration, at any time, users can access the General Terms and Conditions in their User Profile in the "Terms of Use" section.

**Registration** - the process of creating an account by the User after providing the User Data, accepting the GTC and activating the account.

**Platform** - an online platform managed by 4Her, accessible via the 4Her mobile application, a link to which is also available on the website -- 4her-bg.com

**Seller** - User who publishes an advertisement on the Platform and concludes transaction(s) with a Buyer.

**Transaction** - any agreement concluded between Users or between a User and a Guest with the subject of an Item.

**User** - a natural person, a legal entity or a person which the law equates to a legal entity who uses the Platform after logging into their account.

**Service** - providing access to the Platform with the ability to exercise all of the rights set out in these GTC.

# 4. User rights. Free of charge usage of the Platform.

**4.1.** Users can:

**a.** view the content of the Platform, including accessing and familiarising themselves with the content of published advertisements;

**b.** use the Account and its related features (e.g. to use chat and to communicate with other users);

**c.** publish advertisements.

**4.2.** The services of viewing content on the Platform, creating and maintaining the account and publishing advertisements are provided free of charge, with the exception of services that are paid for and described in detail in these GTC, such as the promotion of advertisements and suchlike.

**4.3.** In order to use the services of the Platform in their entirety, Users must have a device connected to the Internet, including a program to view the relevant website (browser) or mobile application. 4Her will make every effort to ensure the continuous operation of the Platform. In order to ensure high quality of services and efficient functioning of the Service, 4Her reserves the right to make interruptions in the functioning of the Platform, as short technical interruptions/maintenance of services are possible, during which some functionalities of the Platform and services provided by 4Her may be limited or unavailable.

**4.4.** Users who communicate with other Users via the chat functionality of the Platform acknowledge being informed that these conversations are not private and that their content may be accessed and processed by 4Her. By accepting these GTC, the User acknowledges that 4Her has the right to access and analyse the content of conversations held through the chat functionality of the Platform in order to enhance the safety of Users, prevent misuse and improve the services of the Website. For more information about how and why 4Her accesses and analyses the content of conversations held through the chat functionality on the Website, please see our Privacy Policy.

# 5. Contract conclusion 

**5.1. 4Her is not a party to the Transactions**, has no control over any element of the Transactions and is not liable to any party in connection with any Transactions made through the Platform.

**5.2.** For the avoidance of doubt, 4Her is not responsible for the quality of the goods, their authenticity, usability or availability for the reason given in 5.1.

**5.3.** The costs of delivery, inspection, test and such like are freely negotiated between Buyer and Seller based on separate legal relationships between the said parties wherein 4Her is not a party.

# 6. Creating and deleting a Profile

**6.1**. Each Guest may create an account and use the Website as a registered User. The account enables the User to use, among other services, the following features of the Website:

**a**. publishing and managing published advertisements;

**b**. monitoring the advertisements of other Users;

**c**. sending and receiving messages to and from other Users;

**6.2.** The user may only be a natural person with full legal capacity, a legal entity or a person which the law equates to a legal entity. In the case of legal entities and organisational entities without legal capacity recognised with legal capacity by the law, only a person authorised to act in this respect on behalf of such entities may create an account on their behalf and carry out any activities on the website on their behalf.

**6.3**. To register an account, you need to:

**a.** fill in the form available in the Application and provide the necessary data, namely: **_your email address_** and **_a unique password_** or **_an external service provider authentication such as Facebook, Apple, Google;_**

**b.** **_read and accept these GTC_** and their Attachments.

**6.4**. By filling in the data required for registration, without any further action on the part of the User, only after clicking on the "Sign up" button a User account is created.

**6.5.** Registration shall be deemed complete upon completion of the steps in 6.4. At this point _an_ _Account Service Agreement_ is concluded.

In order to use all functionalities of the Platform, the User should activate their account. To activate the account, it is necessary to do the following:

**6.5.1.** Signing into a created User account

**6.5.2.** Click on "Confirm account" button

After completing the steps set out in 6.5. above, the User will receive confirmation of their account registration via an account activation code.

**6.6.** If activation does not take place within 1 hour of receipt of the email confirming account registration, the activation code will expire and the account will not be activated. The User may request an activation code for the account an unlimited number of times until the account is activated. However, 4Her urges its users to activate their desired account the first time a confirmation code is requested in order to avoid technical problems and inconveniences.

**6.7** 4Her has the right to terminate the Account Service Agreement with the User if the User:

**a.** has not logged in for more than 24 months. As a result, the User will not be able to use the deleted account. Termination information will be sent to the User 30 days in advance to the email address provided during Registration. The User will be able to express their willingness to continue using the account by sending an email to info@4her-bg.com. The right of 4Her to terminate the Agreement in accordance with this clause does not limit the right of the User to re-register on the Website. However, 4Her does not guarantee that the existing username associated with the account will be available for use during the new registration.

**b**. despite being asked by 4Her to cease certain actions or inactions that violate the points of the GTC or provisions of common applicable law, the User continues to perform the actions specified in the warning of 4Her.

**c**. in the event that the verification referred to in 7.5. below is unsatisfactory or results in the identity of a User being unverifiable.

# 7. Personal data. Privacy

**7.1**. 4Her processes personal data of Users, persons acting on behalf of Users, and Guests in accordance with the Privacy Policy and Cookie Policy.

**7.2**. The User confirms that the data provided during the registration process and during the use of the Website are true, correctly entered and up-to-date and that the User is entitled to use these data. The User undertakes to update the data in case of changes. 4Her reserves the right to block an account if the data provided does not meet the above requirements.

**7.3**. A person acting within the Platform on behalf and at the expense of a User who is a legal entity or a person which the law equates to a legal entity guarantees that they are duly authorised to act and perform all activities on behalf and at the expense of that User within the Website.

**7.4.** The User undertakes to keep the access data to their account confidential and to protect it from access by unauthorized third parties. The User shall immediately inform 4Her if they become aware of any unauthorised third party access to their account and, if possible, change their account password immediately.

**7.5**. In order to ensure the proper functioning of the Website and to protect and ensure the safety of the persons using it, 4Her reserves the right to further verify the validity and authenticity of the data provided by the User and to request the User to confirm their identity, the information contained in the advertisement or the information related to a transaction, which verification shall be carried out in a manner chosen by 4Her. If verification of the details or identity of the User cannot be carried out, 4Her may block or restrict the functionality of the account.

# 8. Publishing of advertisements

**8.1.** 4Her enables the User to publish advertisements on the Website. The publishing of an advertisement on the Website by the User takes place after filling in the appropriate form and clicking on the "Upload product" button.

**8.2**. An advertisement published by the User on the Platform is accessible to any person using the services of the Platform. Along with the advertisement, a contact form is available that allows Users to contact the User who published the advertisement and send them a message. Regardless of the choice made by the User, the phone number of the User in the advertisement can be accessed and seen by everyone, i.e. it is publicly available. The User undertakes not to publish advertisements with contact telephone numbers with added value.

**8.3.** At the time of publishing the advertisement, the User grants 4Her a non-exclusive, territorially unlimited and non-remunerative permission to record, reproduce and distribute all or part of the advertisement for the purpose of displaying it on the Platform, as well as to the partners of 4Her through whom the distribution of the Application and the Website is carried out, and also anywhere via the Internet, including search engines (such as Google), social networks (such as Facebook, Instagram, TikTok). 4Her, as the Administrator of the Platform, has the right to use the advertisements published by users and the content of the Website for the purposes of conducting marketing and/or advertising activities organized by 4Her, as well as any other type of campaign to present and promote the activities of the Platform and 4Her.

**8.4**. By publishing an advertisement, users agree that this advertisement and its content on the Website may be used, in whole or in part, alone or in conjunction with other advertisements, in marketing and/or advertising activities and campaigns to present and promote the activities of 4Her.

**8.5**. Due to the specific nature of the Internet, 4Her does not have full control over the distribution of content published or transmitted via the functionalities of the Website to other users and assumes no responsibility in this respect for the actions of third parties, including in the case of copying and distribution of messages by such persons on websites and portals not linked to the Website.

**8.6.** The content of any advertisement shall comply with the requirements set out in this Section 8 and in these GTC generally, shall be truthful, unequivocal and understandable and shall meet the technical requirements set out by 4Her. The User is free to determine the content of the advertisement, while determining it in compliance with the law and the following requirements:

**a.** The advertisement must be written in Bulgarian and must not contain words that are considered vulgar or offensive. The advertisement may be additionally prepared in a foreign language.

**b.** The User must select a category appropriate for the subject of the advertisement to which the advertisement should be assigned, optionally they can also select an appropriate subcategory or filters.

**c**. The User must indicate the full price in Bulgarian levs (indicating the amount of VAT, if applicable) and, where applicable, indicate that the price is negotiable, that the item is offered free of charge or that the User gives the option to exchange the item for other goods or services.

**d.** The User must indicate the state of the item (e.g. new or used, etc.) where applicable and there is such a parameter specified in the add advertisement form.

**e**. The content of the advertisement should contain a clear, accurate and complete description of the item, including truthful and non-misleading information about its characteristics. Brands and models of items/services not offered in the advertisement are not allowed to be mentioned.

**f**. The title of the advertisement shall not indicate contact details, as such details shall be indicated in the fields of the form provided for this purpose.

**g**. The subject of the advertisement can only be an object owned by the User.

**h.** If several items are offered in the advertisement, they shall meet the following requirements:

- be of the same subcategory.

- all items not offered as a set have a clear and unequivocal unit price indicated.

**i.** It is necessary to indicate whether the advertisement refers to a single part, several parts or a set/package (set means a set of items forming a whole). It is possible for users to add information about the availability of the item in different colours or similar versions in one advertisement.

**j.** It is necessary to indicate whether the price in the advertisement refers to a single item or a set of items. The same item can only be covered by one advertisement at a time, except in cases where the User is selling multiple items together as a "set" and is placing the items in the set separately.

**k.** The content of the advertisement shall not include advertisements, promotional and advertising content, website addresses and other elements leading to a reference to the listed. In case of violation, the Administrator may take down the advertisement immediately.

**l.** Advertisements shall contain at least one personally taken photograph of the item.

**8.7.** If the User publishes an advertisement in the wrong category, 4Her has the right to change the category.

**8.8.** The item in the advertisement shall not represent:

**a**. search for items -- for example an advertisement "looking for black shoes number 37";

**b.** an offer to meet/date or be of a marital and/or sexual nature;

**c.** a prohibited good listed in Attachment 1 "Prohibited Items" and any other good to the extent that transaction therein is excluded by applicable law; a good that is the subject of a crime or that is the subject of a judicial or administrative dispute, or a good that is the subject of an enforcement proceeding, or an item that is part of an asset constituting the estate in bankruptcy.

**8.9**. The publishing of an advertisement on the Website is done by the User by clicking on the "Add" icon or in the ways indicated by unequivocal signs and meaningful expressions.

**8.10.** The publishing of the advertisement on the Website begins immediately after the upload and continues indefinitely until the advertisement is removed by the User. This publishing period may be shorter if one of the following circumstances occurs:

**a**. the User has sold the item.

**b**. the User has altered the advertisement in such a way as to indicate that it relates to a different item from the one to which it originally related.

**c**. the User has deleted the advertisement.

**d.** 4Her has removed an advertisement due to non-compliance with the restrictions set out in point 8.8. or in other circumstances expressly set out in these GTC, e.g. in contravention of common law or non-compliance with Attachment 1.

**8.11.** In the event that 4Her becomes aware of the circumstances set out in 8.8, 4Her shall be entitled to remove the advertisement in accordance with the rules set out in these GTC.

**8.12.** During the period of publishing the advertisement on the Website, the User has the right to change the content of the advertisement and some of its parameters and to delete the advertisement. It is not allowed to replace an item listed in a published advertisement with a new one. The insertion of a new item in violation of this provision will be considered a new advertisement. In such cases, the Administrator reserves the right to remove the advertisement.

**8.13.** The ability to change the content of the advertisement does not apply to changes in the price or other parameters of the published advertisement aimed at avoiding the payment of amounts due to 4Her for the services provided by the Website or obtaining the return of funds due to the User in the cases provided for in the GTC.

**8.14.** After the end of the publishing of the advertisement, regardless of the reason, the advertisement is archived and is not accessible to the User after that point. Access to archived advertisements is only restricted to authorized persons of 4Her.

**8.15.** 4Her visualizes advertisements in the Platform when users perform a general search and browse through the criteria "sort A-Z", "sort Z-A", "price ascending", "price descending", "newest" or "oldest" advertisements.

**8.16**. The User undertakes **not to use in the advertisement** photographs and images that contain visually intrusive and distinctive elements and effects, including but not limited to:

- signs stating "Promotion", "Sale", "Liquidation", "Last units" and other similar statements that would change consumer behaviour by claiming bargains, price reductions, promotional offers, uniqueness or quality claims of the goods or services offered, exhaustion of quantities, validity periods of given conditions, etc.

- visual effects and add-ons on the photographs and images in order to attract the attention of the users, which visually distinguish the respective advertisement from the other advertisements on the Website.

# 9. 4Her liability for published content, prohibition of use of intellectual property

**9.1**. The content published on the Platform, including in particular advertisements, regardless of their form, i.e. text, graphic and video material, is subject to the protection of intellectual property rights, including copyright and intellectual property rights of 4Her Users or third parties. It is prohibited to use this content in any way without the written consent of the holders of these rights. Any collection and processing of data and other information available on the Platform for the purpose of further sharing with third parties within other Websites and outside the Internet is prohibited. It is also prohibited to use the name of the Platform and 4Her, including their distinctive graphic elements, without the consent of 4Her.

**9.2.** The Platform may contain links to other websites. 4Her is not responsible for the privacy policies of websites that it does not administer or for other information contained therein.

**9.3.** This Platform, its providers or third parties shall not be liable under any circumstances for any damages whatsoever including but not limited to damages for loss of profits, loss of information or otherwise arising out of the use, inability to use or the results of the use of this Platform.

# 10. User actions that do not comply with legal requirements or the Website General Terms and Conditions

**10.1.** The User has the right to use the Platform in accordance with its purpose, in compliance with the law and good practices of the information society, respecting the rights and interests of others. In particular, the User undertakes:

**a**. to not take any actions that could interfere with the proper functioning of the Platform, including to not take any actions that interferes with the reproduction of the content of the Platform, their Account or the accounts of other Users, or the IT elements of the Platform.

**b**. to not take illegal actions, including sending or publishing through the use of the functionalities of the Platform content that violates the law, personal rights; content that contains or disseminates child pornography, or terrorist content or content that violates the intellectual property rights of others, as well as content of a discriminatory or racist nature.

**c**. to not trade through the Platform (or take any other actions having a similar effect) the prohibited items listed in Attachment 1 "Prohibited items", and to not sell (or take any other actions having a similar effect) conditionally permitted items without complying with the conditions required under Attachment 1, as well as other items, provided that trade in them is not permitted under applicable law, and items that are or have been the subject of theft or are the subject of pending judicial or administrative proceedings, or items that are the subject of enforcement proceedings or included in the estate of a bankrupt.

**d.** to not mislead users on the Platform, for example by providing false information about an item or concealing relevant information.

**e**. to abstain from unscrupulous behaviour and to not abuse the functionalities of the Platform by using them in contradiction with its purposes and these GTC.

**10.2.** The User is obliged to comply with the provisions set out in point 10.1. of the GTC during any use of the Website and in relation to any functionality offered, including and especially when publishing advertisements and sending messages to other Users.

**10.3**. In the event of changes to the content of an advertisement made by the User after it has been published on the Website, 4Her has the right to edit the content that is not in accordance with the GTC or remove the advertisement.

**10.4.** Any person using the Platform has the option to report to 4Her any illegal or violative of the rules and GTC content disseminated through the Platform via any of the listed contact methods (e.g. email). To the extent possible, the reporting person should provide details to enable 4Her to verify the report of abuse, including the identification number of the advertisement, an explanation of why they believe the content is illegal or in violation of the GTC, and, at their option, contact details.

**10.5.** In the event that 4Her receives reasonable notice (based on a report of abuse or otherwise) that the content in question sent or published through the Website is illegal or violates the provisions of the GTC, 4Her may:

**a.** immediately block the content, including the advertisement. In the event of blocking, the content will not be visible or accessible to persons using the Platform;

**b.** take appropriate investigative action to establish the circumstances of the case, acting proportionately and reasonably, including, for example, by contacting the person who reported the irregularity; the person who published the content in question; the competent public authority; or an external consultant;

**c.** in the event of blocking of content, inform the person whose content has been reported;

**d.** in the cases mentioned above in this provision, 4Her has the right to refuse to provide more detailed information to the User if the notification by a competent government authority, binding legal provisions or the need to ensure the safety of people or their health, as well as the security of the state, implies an obligation to not disclose the information.

**10.6.** If the objection referred to in point 10.7. is supported by evidence, but there are nevertheless no grounds to consider that there is illegality or non-compliance of the content with the GTC, the blocked advertisement shall be activated by 4Her.

**10.7.** 4Her has the right to remove an advertisement or block an account if it suspects that acts may be committed through the advertisement or account that threaten the safety of other Users on the Website, or if the advertisement has a negative impact on the reputation of 4Her or otherwise harms 4Her. It is not permitted to publish on the Website any content or links to websites competing with the Platform.

**10.8.** In the event of a serious violation of the law in the use of the Website, as well as of a significant or repeated violation of the provisions of the GTC, in particular in the event of repeated attempts to publish or disseminate illegal content by a User or by different Users acting jointly and by agreement, including the use of different accounts, 4Her may, in accordance with the principle of proportionality and respect for the freedom to exchange goods, restrict or block temporarily or permanently the use of an account or accounts, which will be equivalent to a temporary suspension of the provision of services to the User, considering the following rules:

**a**. Restricting the account means stopping its functionality. A User whose account has been restricted loses the ability to actively use the Platform, which means that they cannot send messages and publish advertisements. They can, however, browse the Platform, message history and published advertisements.

**b.** Blocking an account means that the User loses the ability to log in to their account.

**10.9.** The business User will be informed in case of blocking of certain content at the latest when the blocking takes effect in the form of an email with information on the blocked content, the reasons for the blocking decision and the basis of the GTC from which it derives.

# 11. Complaints procedure

**11.1** If there are any irregularities in the operation of the Platform, including with respect to individual services of a technical nature, the User should report the problem to 4Her via the "help" section, providing the data necessary to identify the problem.

**11.2.** 4Her makes every effort to provide services of the highest quality. Notwithstanding the foregoing, the User shall have the right to complain, free of charge, about the failure to perform or the inadequate performance by 4Her of the services provided under the GTC.

The complaint may be submitted in one of the following ways:

- sending an email to: info@4her-bg.com or

- in writing to the 4Her address set out above in these GTC.

The complaint must contain at least: the first and last name, the email address of the User with which the account is registered, the advertisement identification number, the circumstances giving rise to the complaint, and the request of the User related to the complaint.

If the data or information provided in the complaint does not allow the complaint to be investigated, 4Her will in a clear way ask the User to clarify any doubts or provide additional information if necessary for 4Her to investigate the complaint.

4Her shall review the complaint within 14 days from the date of receipt or receipt of the additional information referred to in the preceding paragraph. The response to the complaint by 4Her shall be sent by email to the email address with which the account was created, unless the User requests that the response be sent by mail to the mailing address provided in the complaint.

# 12. Complaints and mediation

A User may request mediation to resolve a dispute that arises between the User and 4Her in connection with the Services provided by 4Her, including disputes concerning matters that may be the subject of a complaint as set out in points 11.2. and 13.2. of these GTC. 4Her is available to make arrangements to settle such disputes through mediators. We could provide a list of mediators with whom we cooperate if you email us at rumyana.yordanova@penkov-markov.eu. Mediation is payable, with 4Her bearing a reasonable proportion of any mediation costs.

The option to use mediation is voluntary. Please see also the other dispute resolution methods mentioned in point 13. below.

# 13. Competent State Authority. Extrajudicial settlement of disputes.

**13.1.** The competent supervisory authority for the protection of consumer rights in Bulgaria is the Commission for Consumer Protection with address in the city of Sofia 1000, 4A Slaveykov Sq., floor 3, 4 and 6; phone number: +359 2 933 056 and phone number +359 2 988 4218 and phone number for the consumer: +359 0700 111 22, website: www.kzp.bg.

**13.2.** In case of disputes concerning obligations arising from online sales contracts where no agreement has been reached, the User may refer the dispute to the Alternative dispute resolution for consumers bodies under the terms and conditions of Article 181a and following of the Consumer Protection Act. Further information on alternative dispute resolution for consumer disputes can be found on the website of the Commission for Consumer Protection at www.kzp.bg and on the online dispute resolution platform.

**13.3.** The User has the possibility to use out-of-court means to resolve consumer disputes in accordance with the online dispute resolution procedure developed by the European Commission, available at:

<https://webgate.ec.europa.eu/odr/main/?event=main.home.show>

# 14. Concluding provisions

**14.1.** 4Her reserves the right to amend the General Terms and Conditions in accordance with the following rules:

**a.** Amendments to the GTC may be made for organisational, legal or technical reasons;

**b**. The User will be informed of any amendment by placing information in the Application, visible after proper login to the account;

**c.** The amendments shall take effect within the period specified by 4Her, but not less than 15 days from the notification of the amendment to the GTC, unless the provisions of generally applicable law provide otherwise;

**14.2.** 4Her may amend these GTC without complying with the 15-day notice period set out above, including with immediate effect in the event that:

**a.** it is bound by a legal or regulatory obligation under which it must amend its General Terms and Conditions in a way that prevents it from complying with the aforementioned 15-day notice period;

**b.** it must exceptionally amend its General Terms and Conditions to address an unforeseen and imminent danger related to the protection of online intermediary services, users or business users from fraud, malware, spam, data breaches or other cyber security risks.

**14.3.** The User who does not accept changes to the GTC reserves the right to terminate the Account Service Agreement at any time.

**14.4.** In order to develop its Platform, 4Her has the right to introduce new services and functionalities, which may be preceded by product tests, without prejudice to the previously acquired rights of Users.

**14.5.** Unless otherwise provided by mandatory statutory provisions, the applicable law for contracts between the User and 4Her is Bulgarian law. The choice of Bulgarian law does not deprive the User of the protection afforded to them under provisions that cannot be derogated from by contract under the law that would be applicable in the absence of the choice.

**14.6.** All disputes relating to the services provided by 4Her will be submitted to the competent general courts for adjudication.

**14.7.** The Attachments to these General Terms and Conditions are an integral part hereof.

**14.8.** 4Her can be contacted:
by email at info@4her-bg.com
in matters relating to personal data: rumyana.yordanova@penkov-markov.eu

**14.9.** 4Her reserves the right to update and amend the General Terms and Conditions of the Platform from time to time to reflect any changes to the way the Website operates and the conditions or any changes to legal requirements. The document may be objected to by Customers from the time it is posted on the Platform. In the event of any such change, we will publish the amended version of the Document on the Platform, so we ask you to check the contents of this Document periodically. Any transaction via the Platform shall be subject to the General Terms and Conditions that were in force at the time of the conclusion of the remote contract between the Customer and the Seller.

**14.10**. If any provision of these General Terms and Conditions of use of the Platform is found to be invalid or unenforceable, for whatever reason, this shall not render the remaining provisions invalid or unenforceable.`;
