export const termsOfUseBg = `# Общи условия

# 1.Общи положения

**1.1.** 4Her (фор хър)е електронна платформа (наричана по-долу „Платформата"), достъпна чрез мобилни устройства и компютри под формата на мобилно приложение (апликация).Посочената апликация може да се свали и от линк след зареждане на уебсайта – www.4her-bg.com Платформата се използва свободно от потребителите за публикуване на обяви с цел продажба на стоки и услуги, включително за сключване на договори за покупко-продажба с търговци, публикували обяви в платформата на 4Her, при спазване на правилата за публикуване на обяви и съответните ограничения за това, описани по-долу в тези ОУ.

**1.2.** С посещението си в Платформата Вие се съгласявате с Общите условия за ползване на Платформата. Като продължите престоя си в Платформата декларирате, че сте съгласни и разбирате Общите ни условия.

За избягване на съмнение, уточняваме, че всяко посещение на Платформата означава, че Вие сте се запознали с Общите условия за използването й и сте се съгласили да ги спазвате безусловно.

**1.3.** Общите условия на 4Her обвързват всички посетители на Платформата.

**1.4.** Тези Общи условия имат обвързваща и задължителна сила на договорно споразумение, приложимо за всички версии и устройства, от които са достъпни услугите на Платформата ни.

**1.5.** Условията за ползване на Платформата, включително правилата за регистрация, публикуването на обяви и поръчка на артикули, както и въпросите, свързани с процедурата за плащане и подаване на жалби, са посочени по-долу в тези Общи условия. Всеки, който използва Платформата, е длъжен да се запознае със съдържанието на **този документ**.

**1.6.** Гостите могат да използват Платформата с ограничени функции, при положение че спазват правилата, посочени в настоящите Общи условия, като спазват закона и принципите на добросъвестност.

**1.7.\*\*** 4 \***\*Her** **не е страна по Сделките** , няма контрол върху нито един елемент от Сделките и не носи отговорност спрямо която и да е страна във връзка с осъществените чрез Платформата Сделки, т.е. в случай че поръчате артикул от обява, качена в нашата Платформа, единствен отговорен за качеството, доставката, евентуални дефекти и т.н. е Продавачът, публикувал обявата.

# 2. Данни за администратора на платформата – 4her

**2.1. „4Хър"\*\*** ООД\*\* е дружество със седалище и адрес на управление гр. София, ул. Лавеле 19, ет. 4, офис 5 ЕИК 207248527

**2.2.\*\*** „4Хър" ООД\*\* администрира Платформата 4her, под формата на сайта 4her-bg.com, както и мобилното приложение 4her (всяко от което наричано по-долу за краткост „Платформата").

**2.3.** Можете да се свържете с 4Her на посочения по-горе адрес или на и-мейл адрес info@4her-bg.com

# 3. Определения

**4H\*\*** er\*\* (Администратор/Платформа) - виж. т.2. по-горе;

**Гост** - физическо лице, използващо Платформата, без да има потребителски профил в него или макар да има такъв, използва Платформата, без да е влязло в профила си;

**Посетител** – всяко физическо лице, което е достъпило Платформата, независимо, дали като гост или регистриран потребител;

**Профил** (Акаунт) - съвкупност от данни, с които се асоциира регистриран потребител в Платформата, включително информацията, предоставена от потребителя на Платформата. Правилата, относими към Профила, са поместени по-долу в настоящите ОУ.

**Доставка** - куриерска услуга за транспортиране на стоки, която е заявена от потребителя и за която не отговаря Платформата;

**Ден** - означава 24 последователни часа;

**Категория** – тематична категория (например Дрехи, Обувки, Козметика), към която е разпределена дадена обява. Всяка категория може да бъде разделена на тематично свързани подкатегории или филтри.

**Купувач** - потребител, който сключва сделка с Продавач, като използва Платформата.

**Обява** - предложение за продажба или предоставяне на Артикул, направено от Продавач, което предложение отговаря на изискванията, посочени в Раздел 8 от тези Общи Условия.

**Артикул -** стока или услуга, предмет на Обява.

**Общи условия** („ **ОУ**") – настоящите общи условия и приложенията към тях, определящи правилата за използване на Платформата. Настоящата версия на Общите условия е достъпна на Уебсайта по всяко време, във форма, позволяваща изтеглянето им, запаметяването им на траен носител или принтирането им, както и в мобилното приложение при първоначално влизане в Профила ще изскочи прозорец с възможност за отваряне на ОУ, а впоследствие след регистрация по всяко време потребителите могат да достъпят ОУ в потребителския си Профил в секция „Правила за използване".

**Регистрация** - процесът на създаване на Профил от страна на Потребителя след предоставяне на данните на Потребителя, приемане на ОУ и активиране на Профила.

**Платформа** - онлайн платформа, управлявана от 4Her, достъпна чрез мобилното приложение 4Her, линк към което Приложение има и на уебсайта - 4her-bg.com

**Продавач** - Потребител, който публикува обява на Платформатa и сключва сделка/сделки с Купувач.

**Сделка** - всяко споразумение, сключено между Потребители или между Потребител и Гост с предмет Артикул.

**Потребител** - физическо лице, юридическо лице или лице, което законът приравнява на правен субект, които използват Платформата след влизане в Профила си;

**Услуга** – предоставяне на достъп до Платформата с възможност за упражняване на всички права, посочени в тези ОУ

# 4. Права на потребителите. Безплатно използване на Платформата.

**4.1.** Потребителите могат:

**а.** да преглеждат съдържанието на Платформата, вкл. да достъпват и да се запознават със съдържанието на публикувани обяви;

**б.** да използват Профила и свързаните с него функционалности (напр. да използват чат и да комуникират с други потребители);

**в.** да публикуват обяви.

**4.2.** Услугите за преглеждане на съдържанието на Платформата, създаването и поддръжка на Профила и публикуване на обяви се предоставят безплатно, с изключение на услугите, които са платени и подробно описани в тези ОУ, като например промотиране на обяви и други подобни.

**4.3.** За да използват услугите на Платформата в цялост, Потребителите трябва да имат устройство, свързано към Интернет, включително програма за преглед на съответната интернет страница (браузър) или мобилно приложение. 4Her ще положи всички усилия, за да гарантира непрекъснатото функциониране на Платформата. За да се гарантира високо качество на услугите и ефективно функциониране на Услугата, 4Her си запазва правото да прави прекъсвания във функционирането на Платформата, като са възможни кратки технически прекъсвания/профилактики на услуги, по време на които някои функционалности на Платформата и услугите, предоставяни от 4Her, могат да бъдат ограничени или недостъпни.

**4.4.** Потребителите, които комуникират с други Потребители чрез функционалността за чат на Платформата, потвърждават, че са информирани, че тези разговори не са лични и че тяхното съдържание може да бъде достъпено и обработвано от 4Her. С приемането на тези ОУ Потребителят потвърждава, че 4Her има право да достъпва и анализира съдържанието на разговорите, проведени чрез функционалността за чат на Платформата, с цел да се повиши безопасността на Потребителите, да се предотвратят злоупотреби и да се подобрят услугите на Уебсайта. За повече информация относно това как и защо 4Her осъществява достъп и анализира съдържанието на разговори, проведени чрез функционалностите на чата в Уебсайта, моля, вижте Политиката ни за поверителност.

# 5. Сключване на договор

**5.1. 4Her не е страна по Сделките** , няма контрол върху нито един елемент от Сделките и не носи отговорност спрямо която и да е страна във връзка с осъществените чрез Платформата Сделки.

**5.2.** За избягване на съмнение, 4Her не носи отговорност за качеството на стоките, тяхната автентичност, изправност, нито наличност, поради съображението посочено в 5.1.

**5.3.** Разходите за доставка, преглед, тест и други подобни се договарят свободно между Купувач и Продавач въз основа на отделни правни отношения между посочените страни, по които 4Her не е страна.

# 6. Създаване и изтриване на Профил

**6.1**. Всеки Гост може да си създаде профил и да използва Уебсайта като регистриран Потребител. Профилът дава възможност на Потребителя да използва, наред с другите услуги, следните функционалности на Уебсайта:

**а**. публикуване и управление на публикувани обяви;

**б**. наблюдаване на обявите на други Потребители;

**в**. изпращане и получаване на съобщения до и от други Потребители;

**6.2.** Потребителят може да бъде единствено физическо лице с пълна дееспособност, юридическо лице или лице, което законът приравнява на правен субект. В случай на юридически лица и организационна единица без правосубектност с призната правоспособност от закона, само лице, упълномощено да действа в това отношение от името на тези субекти, може да създаде профил от тяхно име и да извършва каквито и да било дейности на уебсайта от тяхна страна.

**6.3**. За да регистрирате Профил, трябва да:

**а.** попълните формата, налична в Приложението, и предоставите необходимите данни, а именно:_ **Вашия имейл адрес** _ и _ **уникална парола** _ или _ **автентикация чрез външен доставчик на услуги като Facebook, Apple,** _ _ **Google** \_\_ **;** _

**б.** _ **прочетете и приемете настоящите ОУ** _ и Приложенията към тях.

**6.4**. С попълване на данните, необходими за регистрация, без да са необходими допълнителни действия от страна на Потребителя, единствено след клик върху бутона „Sign up"/"Регистрирай се" се създава потребителски Профил.

**6.5.** Регистрацията се смята за завършена с изпълнение на стъпките по 6.4. Към този момент се сключва _споразумението за обслужване на профил_.

За да използва всички функционалности на Платформата, Потребителят следва да активира профила си. За активиране на профила е необходимо да се изпълни следното:

**6.5.1.** Вписване в създаден потребителски Профил

**6.5.2.** Клик върху бутон „Верифицирай акаунт" / „Confirm account"

След изпълнение на посочените по-горе в 6.5. стъпки Потребителят ще получи потвърждение за регистрация на профила си чрез код за активиране на Профила.

**6.6.** Ако активирането не се осъществи в рамките на 1 час от получаване на имейла, потвърждаващ регистрацията на профила, кодът за активиране изтича и акаунтът не се активира. Потребителят може да изисква неограничен брой пъти код за активиране на Профила, до момента на неговата активация.Въпреки това 4Her приканя потребителите си да активират желания от тях Профил при първото поискване на код за потвърждаване, за да се избегнат технически проблеми и неудобства.

**6.7** 4Her ма право да прекрати споразумението с Потребителя за обслужване на Профил, ако Потребителят:

а. не е влизал в профила си повече от 24 месеца. В резултат на това Потребителят няма да има възможност да използва изтрития Профил. Информация за прекратяването на споразумението ще бъде изпратена на Потребителя 30 дни предварително на имейл адреса, посочен по време на Регистрацията. Потребителят ще може да изрази готовността си да продължи да използва Профила, като изпрати имейл на info@4her-bg.com. Правото на 4Her да прекрати споразумението в съответствие с тази точка не ограничава правото на Потребителя да се регистрира отново в Уебсайта. Въпреки това 4Her не гарантира, че съществуващото потребителско име, свързано с профила, ще бъде налично за използване по време на новата регистрация;

**б**. въпреки че е помолен от 4Her да прекрати определени действия или бездействия, които нарушават точките на ОУ или разпоредбите на общоприложимия закон, Потребителят продължава извършването на посочените в предупреждението на 4Her действия;

**в**. в случай че проверката по 7.5. по-долу е незадоволителна или в резултат от нея се окаже, че самоличността на Потребител не може да се потвърди.

# 7. Лични данни. Поверителност

**7.1**. 4Her обработва лични данни на Потребители, лица, действащи от името на Потребители, и на Гости, в съответствие с Политиката за поверителност и Политиката за бисквитки.

**7.2**. Потребителят потвърждава, че данните, предоставени по време на процеса на регистрация и по време на ползването на Уебсайта, са верни, правилно въведени и актуални и че има право да използва тези данни. Потребителят се задължава да актуализира данните в случай на промени. 4Her си запазва правото да блокира профил, ако предоставените данни не отговарят на горните изисквания.

**7.3**. Лице, действащо в рамките на Платформата от името и за сметка на Потребител, който е юридическо лице или лице, което законът приравнява на правен субект, гарантира, че той или тя е надлежно упълномощен/а да действа и извършва всички дейности от името и за сметка на този Потребител в рамките на Уебсайта.

**7.4.** Потребителят се задължава да пази данните за достъп до профила си в тайна и да го предпази от достъп от неоторизирани трети лица. Потребителят незабавно следва да информира 4Her, ако узнае за какъвто и да било неоторизиран достъп на трети лица до неговия профил и, ако е възможно, да промени паролата на профила си незабавно.

**7.5**. За да гарантира правилното функциониране на Уебсайта, да защити и осигури безопасността на лицата, които го използват, 4Her си запазва правото да извършва допълнителна проверка на валидността и истинността на предоставените от Потребителя данни и да поиска от Потребителя да потвърди самоличността си, съдържащата се в обявата информация или информацията, свързана с дадена сделка, като тази проверка се извършва по начин, избран от 4Her. Ако проверката на данните или самоличността на потребителя не може да се осъществи, 4Her може да ограничи или блокира функционирането на Профила.

# 8. Публикуване на обяви

**8.1.** 4Her дава възможност на Потребителя да публикува обяви в Уебсайта. Публикуването на обява на Уебсайта от потребителя се извършва след попълване на подходящата форма и натискане на бутон „качи обява".

**8.2**. Обява, публикувана от Потребителя в Платформата, е достъпна за всяко лице, което използва услугите на Платформата. Заедно с обявата е налична и контактната форма, която позволява на потребителите да се свържат с Потребителя, публикувал обявата, и да му изпратят съобщение. Независимо от избора на Потребителя, телефонният му номер в обявата може да бъде достъпен и видим от всички, тоест е с публичен достъп. Потребителят се задължава да не публикува обяви с посочени за контакт телефонни номера с добавена стойност.

**8.3.** В момента на публикуване на обявата, Потребителят предоставя на 4Her неизключително, териториално неограничено и безвъзмездно разрешение за записване, възпроизвеждане и разпространение на цялата или част от обявата с цел нейното показване в Платформата, както и на партньорите на 4Her, чрез които се извършва разпространението на Приложението и Уебсайта, а също така и навсякъде чрез Интернет, включително търсачки (като Google), социални мрежи (като Facebook, Instagram, Tiktok). Предоставянето на разрешението е необходимо за ползването на пълния набор от функционалности на Уебсайта. 4Her в качеството си на Администратор на Платформата има право да използва публикуваните от потребителите обяви и съдържание на сайта за целите на провеждане на организираните от 4Her маркетинг и/или рекламни активности, както и всякакъв друг вид кампании за представяне и популяризиране дейността на Платформата и 4Her.

**8.4**. С публикуването на обява потребителите се съгласяват тази обява и нейното съдържание на сайта да бъдат ползвани изцяло или отчасти, самостоятелно или заедно с други обяви, в маркетинг и/или рекламни активности и кампании за представяне и популяризиране дейността на 4Her.

**8.5**. Поради специфичното естество на Интернет, 4Her не разполага с пълен контрол върху разпространението на съдържание, публикувано или предадено чрез функционалностите на Уебсайта на други Потребители и не поема отговорност в това отношение за действията на трети лица, включително в случай на копиране и разпространение на съобщения от такива лица в уебсайтове и портали, които не са свързани с Уебсайта.

**8.6.** Съдържанието на всяка обява следва да отговаря на изискванията, посочени в този раздeл 8 и изобщо в тези ОУ, да бъде вярно, недвусмислено и разбираемо и да отговаря на техническите изисквания, посочени от 4Her. Потребителят е свободен да определи съдържанието на обявата, като при определянето му спазва закона и следните изисквания:

**а.** Обявата да бъде написана на български език и да не съдържа думи, които се считат за вулгарни или обидни. Обявата може да бъде допълнително подготвена на чужд език;

**б.** Потребителят трябва да избере една подходяща за предмета на обявата категория към които обявата да бъде причислена, по желание може да избере и подходяща подкатегория или филтри;

**в**. Потребителят трябва да посочи цялата цена в български лева (като посочи размера на ДДС, ако е приложимо) и, когато е приложимо, да посочи, че цената е по договаряне, че артикулът се предлага безплатно или, че потребителят дава възможност за замяна на артикула за друга стоки или услуги.

**г.** Потребителят трябва да посочи състоянието на артикула (напр. нов или използван или др.), където е приложимо и има посочен такъв параметър във формата за добавяне на обява;

**д**. съдържанието на обявата следва да съдържа ясно, точно и пълно описание на артикула, включително достоверна и неподвеждаща информация за неговите характеристики. Не се допуска да се посочват марки и модели на артикули/услуги, които не се предлагат в обявата;

**е**. Заглавието на обявата не може да посочва данни за контакт, такива данни се посочват в полетата на формата, предназначени за тази цел;

**ж**. Предметът на обявата може да бъде единствено предмет, притежаван от Потребителя.

**з.** В случай че в обявата се предлагат няколко артикула, те трябва да отговарят на следните изисквания:

(i) да бъдат от една и съща подкатегория;

(ii) всички артикули, които не се предлагат в комплект, да имат посочена ясна и недвусмислена единична цена.

**и.** Необходимо е да се посочи дали обявата се отнася до една част, няколко части или комплект/опаковка (комплект означава комплект от елементи, съставляващи едно цяло). Възможно е потребителите да добавят информация за наличността на артикула в различни цветове или подобни версии в една обява;

**й.** Необходимо е да се посочи дали цената в Обявата се отнася до един Артикул или комплект от Артикули. Един и същ артикул може да бъде обхванат само от една обява в даден момент, с изключение на случаите, в които потребителят продава няколко артикула заедно като „сет" и пуска поотделно артикулите от сета.

**к.** Съдържанието на обявата не може да включва реклами, промоционално и рекламно съдържание, адреси на уебсайтове и други елементи, водещи до препращане към изброените. При нарушение, администраторът може да свали обявата незабавно.

**м.** Обявите следва да съдържат поне една лично направена снимка на артикула.

**8.7.** Ако Потребителят публикува обява в грешна категория, 4Her има право да промени Категорията;

**8.8.** Артикулът в обявата не може да представлява:

**а**. търсене на артикули – например обява „търся черни обувки номер 37";

**б.** предложение за срещи/запознанства или да има брачен и/или сексуален характер;

**в.** забранена стока от изброени в Приложение 1 "Забранени артикули" и всяка друга стока, доколкото търговията с нея е изключена от приложимото законодателство; стока, предмет на престъпление или за която е налице съдебен или административен спор, или стока, предмет на производство по принудително изпълнение или артикул, който е част от имущество, представляващо маса на несъстоятелност.

**8.9**. Публикуването на обява на Уебсайта се извършва от Потребителя чрез кликване върху иконата „Качи обява" или по указаните с недвусмислени знаци и смислови изрази начини.

**8.10.** Публикуването на Обявата в Уебсайта започва веднага след добавянето и продължава неограничено до момента на сваляне на Обявата от Потребителя. Този период на публикация може да бъде по-кратък при настъпването на едно от следните обстоятелства:

**а**. Потребителят е продал артикула;

**б**. Потребителят е променил обявата по такъв начин, който да показва, че се отнася за различен артикул спрямо този, за който първоначално тя се е отнасяла;

**в**. Потребителят е изтрил обявата.

**г.** 4Her е премахнал обява, поради неспазване на посочените ограничения в т.8.8. или при други хипотези, изрично посочени в тези ОУ, напр. при противоречие с общоприложимото законодателство или неспазване на Приложение 1.

**8.11.** В случай че 4Her узнае за настъпването на обстоятелствата, посочени в 8.8. има право да премахне обявата в съответствие с правилата, посочени в тези ОУ.

**8.12.** В периода на публикуване на обявата на Уебсайта, Потребителят има право да променя съдържанието на обявата и някои от нейните параметри и да изтрие обявата. Не се допуска замяната на посочен в публикувана обява Артикул с нов. Въвеждането на нов Артикул в нарушение на това условие ще се счита за нова обява. В тези случаи Администраторът си запазва правото да премахне обявата.

**8.13.** Възможността за промяна на съдържанието на обявата не се отнася за промени в цената или други параметри на публикуваната обява, целящи избягване на плащането на дължимите суми на 4Her за услугите, предоставяни от Уебсайта, или получаване на връщането на средствата, дължими на Потребителя в случаите, предвидени в ОУ.

**8.14.** След края на публикуване на обявата, независимо от причината, обявата се архивира и не е достъпна за Потребителя след този момент. Достъп до архивирани обяви имат единствено оторизирани лица от 4Her.

**8.15.** 4Her визуализира в Платформата обяви при търсене и разглеждане при извършване на общо търсене от страна на потребителите чрез критериите "сортирай А-Я", „сортирай Я-А", „цена възходящ", „цена низходящ", „най-нови" или „най-стари" обяви.

**8.16**. Потребителят се задължава **да не използва в обявата** снимки и изображения, които съдържат визуално натрапчиви и отличаващи се елементи и ефекти, включително, но не само:

(i) надписи, гласящи "Промоция", "Разпродажба", "Ликвидация", "Последни бройки" и други подобни изявления, които биха променили потребителското поведение чрез твърдения за изгодност на сделката, намаления на цени, промоционални оферти, уникалност или претенции за качество на предлаганите стоки или услуги, изчерпване на количества, срокове за валидност на дадени условия и пр.;

(ii) визуални ефекти и добавки върху снимките и изображенията с цел привличане на вниманието на потребителите, които видимо открояват визуално съответната обява от останалите обяви в сайта.

# 9. Отговорност на 4Her за публикувано съдържание, забрана за използване на интелектуална собственост

**9.1**. Съдържанието, публикувано на Платформата, включително в частност обявите, независимо от тяхната форма, т.е. текстови, графични и видео материали, е обект на защита на правата на интелектуална собственост, включително авторски права и права на интелектуална собственост на 4Her Потребителите или трети лица. Забранено е използването на това съдържание по какъвто и да е начин без писменото съгласие на лицата, които са титуляри на тези права. Всяко събиране и обработка на данни и друга информация, достъпна на Платформата, с цел по-нататъшно споделяне с трети страни в рамките на други Уебсайтове и извън Интернет е забранено. Също така е забранено използването на наименованието на Платформата и 4Her, включително характерни техни графични елементи, без съгласието на 4Her.

**9.2.** Платформата може да съдържа линкове към други сайтове. 4Her не носи отговорност за политиката на поверителност на уебсайтове, които не администрира, както и за друга информация, съдържаща се в тях.

**9.3.** Тази Платформа, нейните доставчици или трети лица не носят никаква отговорност при никакви обстоятелства за каквито и да е щети включително, но не само щети от пропуснати ползи, от загуба на информация или други, произтекли от употребата, невъзможността за употреба или резултатите от употребата на тази Платформа.

# 10. Действия на потребителите, които не са съобразени със законовите изисквания или с ОУ за ползване на Уебсайта

**10.1.** Потребителят има право да използва Платформата в съответствие с целта й, в съответствие със закона и добрите практики на информационното общество, като зачита правата и интересите на другите. По-специално, Потребителят се задължава:

**а**. да не предприема действия, които биха могли да възпрепятстват правилното функциониране на Платформата, включително да не предприема действия, които пречат на възпроизвеждането на съдържанието на Платформата, Профила си или Профилите на други Потребители, или на IT елементите на Платформата;

**б**. да не предприема незаконни действия, включително изпращане или публикуване чрез използване на функционалностите на Платформата на съдържание, което нарушава закона, лични права; съдържание, което съдържа или разпространява детска порнография, или терористично съдържание или съдържание, което нарушава правата на интелектуална собственост на други лица, както и съдържание с дискриминационен или расистки характер;

**в**. да не извършва чрез Платформата сделка (или да не предприема други действия със сходен ефект) на забранените артикули, изброени в Приложение 1 „Забранени артикули", както и да не извършва продажба (или да не предприема други действия със сходен ефект) на разрешени под условие артикули, без да отговаря на условията, изисквани в съответствие с Приложение 1, както и други артикули, при условие че търговията с тях е недопустима съгласно приложимото законодателство, както и артикули, които са или са били предмет на кражба или са предмет на висящо съдебно или административно производство, или артикули, предмет на изпълнително производство или включени в масата на несъстоятелност;

**г.** да не подвежда потребителите в Платформата, например като предоставя невярна информация за Артикул или прикрива съответна информация;

**д**. да се въздържа от недобросъвестно поведение и да не злоупотребява с функционалности на Платформта, като ги използва в противоречие с целите й и настоящите ОУ.

**10.2.** Потребителят е длъжен да спазва правилата, посочени в точка 10.1 от ОУ, по време на всеки един момент от ползването на Уебсайта и спрямо всяка предлагана функционалност, включително и най-вече когато публикува обяви и изпраща съобщения до други Потребители.

**10.\*\*** 3\*\*. В случай на промени в съдържанието на обява, направени от Потребителя след публикуването й в Уебсайта, 4Her има право да редактира съдържанието, което не е в съответствие с ОУ или да премахне обявата.

**10.\*\*** 4 \***\*.** Всяко лице, което използва Платформата, има възможност да докладва на 4Her всяко незаконно или противоречащо на правилата и ОУ съдържание, разпространявано чрез Платформата,чрез някой от изброените начини за контакт (напр. чрез имейл) . Доколкото е възможно, докладващото лице следва да предостави данни, които да позволят на 4Her да провери доклада за злоупотреба, включително идентификационния номер на обявата, обяснение на причините, поради които счита, че съдържанието е незаконно или противоречи на ОУ, и по негов избор, данни за контакт.

**10.5.** В случай на получаване на обосновано съобщение (въз основа на доклад за злоупотреба или по друг начин), че въпросното съдържание, изпратено или публикувано чрез Уебсайта, е незаконно или нарушава разпоредбите на ОУ, 4Her може:

**а.** незабавно да блокира даденото съдържание, включително обявата. В случай на блокиране, съдържанието няма да бъде видимо или достъпно за лица, които използват Платформата;

**б.** да предприеме съответните действия по разследване, за да установи обстоятелствата по случая, като действа пропорционално и разумно, включително например чрез осъществяване на контакт с лицето, подало сигнала за нередност; с лицето, публикувало съответното съдържание; с компетентния държавен орган или с външен консултант;

**в.** в случай на блокиране на дадено съдържание да информира лицето, чието съдържание е било докладвано;

**г.** в случаите посочени по-горе в този член 4Her има право да откаже предоставянето на по-детайлна информация на Потребителя, ако уведомяването от компетентен държавен орган, задължаващи законови разпоредби или необходимостта да се гарантира безопасността на хората или здравето им, както и сигурността на държавата, предполага задължение да не се разкрива информацията.

**10.\*\*** 6 \***\*.** Ако възражението, посочено в точка 10.7., е подкрепено с доказателства, но въпреки това несъмнено липсват основания да се счита, че е налице незаконосъобразност или несъответствие на даденото съдържание с ОУ блокираната обява се активира от 4Her.

**10.\*\*** 7 \***\*.** 4Her има право да премахне обява или да блокира Профил, ако има подозрение, че чрез обявата или Профила могат да бъдат извършени действия, застрашаващи безопасността на други Потребители в Уебсайта, както и в случай че обявата има негативно влияние върху доброто име на 4Her или по друг начин уврежда 4Her. Не се допуска публикуването в Уебсайта на съдържание или линкове към конкурентни на Платформата уебсайтове;

**10.\*\*** 8 \***\*.** В случай на сериозно нарушение на закона при използване на Уебсайта, както и на съществено или повтарящо се нарушение на разпоредбите на ОУ, по-специално в случай на повторни опити за публикуване или разпространение на незаконно съдържание от даден потребител или от различни Потребители, действащи съвместно и по договорка, включително и при използването на различни профили, 4Her може, в съответствие с принципа на пропорционалност и зачитане на свободата за обмен на стоки, да ограничи или да блокира временно или за постоянно използването на Профил или Профили, което ще бъде равносилно на временно спиране на предоставянето на услуги на Потребителя, като се вземат предвид следните правила:

**а**. Ограничаване на профила означава спиране на функционалността му. Потребителят, чийто профил е бил ограничен, губи възможността да използва Платформата активно, което означава, че не може да изпраща съобщения и публикува обяви. Той може обаче да разглежда Платформа, историята на съобщенията и публикуваните обяви.

**б.** Блокирането на профил означава, че потребителят губи възможността да влезе в профила си.

**10.\*\*** 9 \***\*.** Бизнес потребителят ще бъде информиран в случай на блокиране на дадено съдържание най-късно, когато блокирането влезе в сила под формата на имейл с информация относно блокираното съдържание, мотивите за решението за блокиране и основанието от ОУ, от която произтича то.

# 11. Процедура по разглеждане на жалби

**11.\*\*** 1\*\*Ако има някакви нередности в работата на Платформата, включително по отношение на индивидуални услуги от технически характер, Потребителят следва да докладва за проблема на 4Her чрез секция „help", като предостави данните, необходими за идентифициране на проблема.

**11.2.** 4Her полага всички усилия да предоставя услуги от най-високо качество. Независимо от горепосоченото, Потребителят има право да подаде безплатно жалба относно неизпълнението или неточното изпълнение от страна на 4Her на услугите, предоставяни съгласно ОУ.

Жалбата може да бъде подадена по един от следните начини:

- изпращане на имейл до: info@4her-bg.com или

- в писмен вид на адреса на 4Her, посочен по-горе в тези ОУ.

Жалбата трябва да съдържа най-малко: собствено и фамилно име, имейл адрес на потребителя, с който Профилът е регистриран, идентификационен номер на обявата, обстоятелства, които пораждат жалбата, както и искането на потребителя, свързано с жалбата.

Ако предоставените в жалбата данни или информация не позволяват да се направи проверка по жалбата, 4Her по ясен начин ще поиска от потребителя да изясни всякакви съмнения или да предостави допълнителна информация, ако е необходимо, за да може 4Her да разгледа жалбата.

4Her разглежда жалбата в срок от 14 дни от датата на получаването й или от получаването на допълнителната информация, посочена по-горе в предходния параграф. Отговорът на жалбата от страна на 4Her се изпраща по електронна поща на адреса на електронната поща, с която Профилът е създаден, освен ако Потребителят не поиска отговорът да бъде изпратен по пощата на пощенския адрес, посочен в жалбата.

# 12. Жалби и медиация

Потребител може да поиска посредничество, за да разреши спор, възникнал между него и 4Her във връзка с услугите, предоставяни от 4Her, включително спорове относно въпроси, които могат да бъдат предмет на жалба, посочени в точки 11.2 и 13.2 от настоящите ОУ. 4Her е на разположение да направи необходимото за постигане на споразумение по такива спорове чрез медиатори. Бихме могли да предоставим списък с медиатори, с които си сътрудничим ако ни пишете на имейл rumyana.yordanova@penkov-markov.eu. Медиацията се заплаща, като 4Her поема разумна част от всички разходи за медиация.

Възможността за използване на медиация е доброволна. Моля вижте и другите способи за разрешаване на спорове, посочени в чл. 13 по-долу.

# 13. Компетентен държавен орган. Извънсъдебно уреждане на спорове.

**13.1.** Компетентен надзорен орган за защита правата на потребителите в България е Комисията за защита на потребителите с адрес: 1000 гр. София, пл."Славейков" № 4А, ет.3, 4 и 6; тел.: 02/933 056 и тел. 02/9884218 и телефон на потребителя: 0700 111 22, уебсайт: www.kzp.bg.

**13.2.** При спорове относно задължения, произтичащи от договори за онлайн продажби, при които не е постигнато споразумение, Потребителят може да отнесе спора към органите за алтернативно решаване на потребителски спорове (АРС) при условията и по реда на чл.181а и сл. от ЗЗП. Допълнителна информация за алтернативното решаване на потребителски спорове може да се открие в уебсайта на Комисията за защита на потребителите на адрес www.kzp.bg и в платформата за онлайн решаване на спорове ОРС.

**13.3.** Потребителят има възможност да използва извънсъдебни начини за разглеждане на потребителски спорове в съответствие с процедурата за разрешаване на онлайн спорове, разработена от Европейската комисия, достъпна на:

[https://webgate.ec.europa.eu/odr/main/?event=main.home.show](https://webgate.ec.europa.eu/odr/main/?event=main.home.show)

# 14. Заключителни разпоредби

**14.1.** 4Her си запазва правото да изменя Общите условия при спазване на следните правила:

**а.** Промените в ОУ могат да се извършват в резултат на организационни, правни или технически причини;

**б**. Потребителят ще бъде информиран за всяка промяна чрез поставяне на информация в Приложението, видима след правилно влизане в Профила;

**в.** Промените влизат в сила в срока, посочен от 4Her, но не по-малко от 15 дни от уведомяването за изменението на ОУ, освен ако разпоредбите на общоприложимото законодателство предвиждат друго;

**14.2.** 4Her може да изменя настоящите ОУ, без да спазва посочения по-горе 15-дневен срок за предизвестие, включително с незабавно действие, в случай че:

**а.** е обвързан с правно или регулаторно задължение, по силата на което той трябва да измени своите общи условия по начин, който не му позволява да спази гореспоменатия 15-дневен срок за предизвестие;

**б.** трябва по изключение да измени своите общи условия, за да преодолее непредвидена и непосредствена опасност, свързана със защитата на посредническите онлайн услуги, потребителите или бизнес ползвателите от измами, зловреден софтуер, спам, нарушения на сигурността на данните или от други рискове, свързани с киберсигурността.

**14.3.** Потребителят, който не приема промени в ОУ, си запазва правото да прекрати споразумението за обслужване на Профила по всяко време.

**14.4.** За да развива Платформата си 4Her има право да въведе нови услуги и функционалности, които могат да бъдат предшествани от продуктови тестове, без с това да се засягат придобитите права на Потребителите преди това.

**14.5.** Освен ако не е предвидено друго от задължителни законови разпоредби, приложимото право за договори между Потребителя и 4Her е българското законодателство. Изборът на българското законодателство не лишава Потребителя от защитата, предоставена му съгласно разпоредби, които не могат да бъдат дерогирани с договор, по силата на закона, който би бил приложим при липса на избор.

**14.6.** Всички спорове, свързани с услугите, предоставяни от 4Her, ще бъдат предоставени за разглеждане на компетентните общи съдилища.

**14.7.** Приложенията към настоящите Общи условия са неразделна част от тях.

**14.8.** Контакт с 4Her може да се осъществи:

на имейл адрес info@4her-bg.com

при въпроси, свързани с личните данни: rumyana.yordanova@penkov-markov.eu

**14.9.** 4Her си запазва правото да актуализира и изменя Общите условия на Платформата периодично, за да отразява всякакви промени на начина, по който уебсайта функционира и условията или всякакви промени на законовите изисквания. Документът може да се противопостави на Клиентите от момента, в който е публикуван на Платформата. В случай на такава промяна ние ще публикуваме на Платформата изменената версия на Документа, поради което Ви молим периодично да проверявате съдържанието на настоящия Документ. Към всяка сделка през Платформата се прилагат общите условия, които са били в сила към момента на сключването на договора от разстояние между Клиента и Продавача.

**14.10**. Ако някоя от разпоредбите на настоящите общи условия за ползване на Платформата се окажат недействителни или неприложими, независимо от причината за това, то това не влече недействителността или неприложимостта на останалите разпоредби.`;
