import React, { useState } from "react";
import useLanguage from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HelpPage.css";

function Help() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const { language } = useLanguage();
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    setIsSending(true);
    axios
      .post("https://for-her-shop-v2.herokuapp.com/api/v1/auth/user/feedback", {
        name,
        email,
        message,
      })
      .then(() => {
        setIsSending(false);
        setIsSent(true);
        navigate("/");
        alert(language.messageSent);
      })
      .catch((error) => {
        console.error(error);
        setIsSending(false);
      });
  }

  return (
    <div className="helpContainer d-flex justify-content-center align-items-center vh-100">
      <form
        onSubmit={handleSubmit}
        className="p-5 bg-light rounded-3 shadow text-center"
        style={{ width: "75%" }}
      >
        <div className="mb-3">
          <input
            placeholder={language.name}
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{
              backgroundColor: "#F7F0F5",
              border: "none",
            }}
            className="helpPage-formControl form-control"
            required
          />
        </div>
        <div className="mb-3">
          <input
            placeholder={language.email}
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            style={{
              backgroundColor: "#F7F0F5",
              border: "none",
            }}
            className="helpPage-formControl form-control"
            required
          />
        </div>
        <div className="mb-3">
          <textarea
            placeholder={language.message}
            id="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            style={{
              backgroundColor: "#F7F0F5",
              border: "none",
            }}
            className="helpPage-formControl form-control"
            required
            rows="5"
          />
        </div>
        <button
          type="submit"
          disabled={isSending || isSent}
          className="btn "
          style={{
            backgroundColor: "#99EDC3",
            border: "none",
            color: "#373737",
            fontWeight: "bold",
          }}
        >
          {isSending ? "Sending..." : isSent ? "Sent!" : language.send}
        </button>
      </form>
    </div>
  );
}

export default Help;
