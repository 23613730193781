import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "./FavouritePage.css";
import CatalogItem from "../../components/CatalogItem";
import useProduct from "../../hooks/useProduct";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const FavouritePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { addOrRemovFromFavourites, favourites, getFavourites } = useProduct();
  const [favProducts, setFavProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFavourites().then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFavProducts([...favourites]);
  }, [favourites]);

  const handleProductClick = (product) => {
    const id = product.id;
    const title = product.title;
    navigate(`${location.pathname}/${id}/${title}`);
  };

  const onFavouriteClick = (event, productId, isFavourite) => {
    event.stopPropagation();
    addOrRemovFromFavourites(productId, isFavourite);
  };

  if (isLoading) {
    return (
      <div className="favouritePage-container d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!isLoading && !favProducts.length) {
    return (
      <div className="favouritePage-container d-flex justify-content-center align-items-center">
        <span>Your favourites list is empty</span>
      </div>
    );
  }

  return (
    <div className="favouritePage-container">
      <Container>
        <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
          {favProducts.map((product) => (
            <CatalogItem
              key={product.id}
              product={product}
              isFavourite
              onClick={() => handleProductClick(product)}
              onFavClick={(event) => onFavouriteClick(event, product.id, true)}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default FavouritePage;
