import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Badge from "react-bootstrap/Badge";
import NavDropdown from "react-bootstrap/NavDropdown";
import useLanguage from "../hooks/useLanguage";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { FaUserCircle } from "../icons";
import "./NavBar.css";
import DownloadAppModal from "./DownloadAppModal";

function NavBar() {
  const { user, signOut } = useAuth();
  const {
    language,
    changeLanguageToEnglish,
    changeSelectedLanguageToEnglish,
    changeLanguageToBulgarian,
    changeSelectedLanguageToBulgarian,
  } = useLanguage();

  const [downloadAppModalShow, setDownloadAppModalShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const openDownloadAppModal = () => {
    setDownloadAppModalShow(true);
  };

  const closeDownloadAppModal = () => {
    setDownloadAppModalShow(false);
  };

  return (
    <>
      <Navbar
        style={{ backgroundColor: "#C2AFF0" }}
        variant="dark"
        expand="sm"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid className="m-0 w-100">
          <div className="navBar-left">
            <Nav.Item style={{ paddingLeft: "4px" }}>
              <Nav.Link
                onClick={() => navigate(`/`)}
                eventKey="1"
                style={{
                  color: "#373737",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {language.home}
              </Nav.Link>
            </Nav.Item>
            <NavDropdown
              style={{ paddingLeft: "0.5rem", color: "#373737" }}
              title={language.language}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  changeLanguageToBulgarian();
                  changeSelectedLanguageToBulgarian();
                }}
              >
                <img
                  src={require("../bulgarianFlag.png")}
                  width="20"
                  height="20"
                  className="d-inline-block align-top bg-light rounded"
                  alt="Bulgarian flag"
                />{" "}
                {language.bulgarian}
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  changeLanguageToEnglish();
                  changeSelectedLanguageToEnglish();
                }}
              >
                {" "}
                <img
                  src={require("../englandFlag.png")}
                  width="20"
                  height="20"
                  className="d-inline-block align-top bg-light rounded"
                  alt="English flag"
                />{" "}
                {language.english}
              </NavDropdown.Item>
            </NavDropdown>
          </div>

          <Navbar.Toggle
            className="text-right"
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="mr-auto">
              {user ? (
                <div className="navBar-right">
                  <NavDropdown
                    className="navBar-dropdown"
                    align="end"
                    title={
                      <div
                        style={{
                          display: "flex",
                          color: "#F7F0F5",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FaUserCircle
                            size={24}
                            style={{ paddingRight: "4px" }}
                          />{" "}
                          {user.name}
                          {!user.confirmed ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <Badge bg="warning">1</Badge>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    }
                    id="collasible-nav-dropdown"
                  >
                    {!user.confirmed ? (
                      <NavDropdown.Item
                        onClick={() => navigate("/confirmAccount")}
                      >
                        {language.confirmAccount} <Badge bg="warning">1</Badge>
                      </NavDropdown.Item>
                    ) : null}
                    <NavDropdown.Item onClick={() => navigate("/settings")}>
                      {language.settings}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => navigate("/myProducts")}>
                      {language.myProducts}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => navigate("/favourites")}>
                      {language.favourites}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={async () => {
                        await signOut();
                        navigate("/");
                      }}
                    >
                      {language.signOut}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Item
                    className="navItem-buttons"
                    style={{ paddingLeft: "4px" }}
                  >
                    <Nav.Link
                      onClick={() => openDownloadAppModal()}
                      eventKey="3"
                      style={{
                        color: "#373737",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "10px 20px",
                      }}
                    >
                      {language.getTheApp}
                    </Nav.Link>
                  </Nav.Item>
                </div>
              ) : (
                <div className="navBar-right">
                  <Nav.Item
                    className="navItem-buttons"
                    style={{ paddingLeft: "4px" }}
                  >
                    <Nav.Link
                      onClick={() =>
                        navigate(
                          `/login?redirect=${encodeURIComponent(currentUrl)}`
                        )
                      }
                      eventKey="2"
                      style={{
                        color: "#373737",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        backgroundColor: "#99EDC3",
                        padding: "10px 20px",
                      }}
                    >
                      {language.login}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="navItem-buttons"
                    style={{ paddingLeft: "4px" }}
                  >
                    <Nav.Link
                      onClick={() => openDownloadAppModal()}
                      eventKey="3"
                      style={{
                        color: "#373737",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "10px 20px",
                      }}
                    >
                      {language.getTheApp}
                    </Nav.Link>
                  </Nav.Item>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <DownloadAppModal
        show={downloadAppModalShow}
        handleClose={closeDownloadAppModal}
        message={language.appAvailableOn}
      />
    </>
  );
}

export default NavBar;
