export const languages = {
  en: {
    home: "Home",
    termsOfUse: "Terms",
    allRightsReserved: "© 4Her LTD 2023 All rights reserved",
    login: "Login",
    buyOrSell:
      "Introducing our new women's marketplace! Find clothing, shoes, cosmetics, and accessories to buy or sell. Discover your perfect fit and style, hassle-free. Join us today and shop stylish and affordable items!",

    downloadApp: "Download the App for free:",
    downloadOnApp: "Download on the App Store",
    downloadOnGoogle: "Download on Google Play",
    passwordHelperText:
      "Use a combination of 8 uppercase and lowercase letters, numbers, and special characters",
    help: "Help",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send",
    messageSent: "Message sent!",
    language: "Language",
    bulgarian: "Bulgarian",
    english: "English",
    privacyPolicy: "Privacy",
    iHaveReadAndAgreeToTheTermsOfUse:
      "I have read and agree to the Terms of use",
    continue: "Continue",
    close: "Close",
    decliningTermsOfUse:
      "Declining these terms of use will result in immediate logout from the application.",
    areYouSureYouWantToProceed: "Are you sure you want to proceed?",
    yes: "Yes",
    no: "No",
    getTheApp: "Get the App",
    settings: "Settings",
    myProducts: "My products",
    favourites: "Favourites",
    signOut: "Sign out",
    confirmEmail: "Confirm email",
    confirmAccount: "Confirm account",
    confirmationCode: "Confirmation code",
    appAvailableOn: "App available on App Store and Google Play Store",
    clothes: "Clothes",
    shoes: "Shoes",
    cosmetics: "Cosmetics",
    accessories: "Accessories",
    enhanceYourBeauty: "Enhance your beauty with our wide range of cosmetics",
    findThePerfectAcc: "Find the perfect accessories to complement your style",
    exploreTrendyShoe: "Explore trendy shoe collection for all occasions",
    discoverLatestClothing: "Discover latest clothing collection",
    loading: "Loading...",
    search: "Search",
    price: "Price",
    clearFilters: "Clear Filters",
    thereAreNoItems: "There are no items matchin your criteria",
    bgn: "bgn",
    description: "Description",
    seller: "Seller",
    views: "Views",
    toContactTheSellerLogin: "To contact the seller you need to login first",
    toUseTheFullFunc: "To use the full functionality download the App:",
    yourEmail: "Your Email",
    youDidntReceiveCode: "You didn't receive a code?",
    sendAgain: "Send again",
    sendCode: "Send Code",
    confirmation: "Confirmation",
    areYouSureThisActionCannotBeUndone:
      "Are you sure? This action cannot be undone.",
    loginToYourAccount: "Login to your Account",
    enterEmail: "Enter email",
    enterPassword: "Enter password",
    signIn: "Sign in",
    dontHaveAnAccount: "Don't have an account?",
    signUp: "Sign up",
    orSignInWith: "or sign in with",
    loginWithFacebook: "Login with Facebook",
    forgotPassword: "Forgot password",
    createYourAccount: "Create your Account",
    pleaseEnterValidEmail: "Please enter a valid email address.",
    passwordMustContain:
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character",
    enterRepeatPass: "Enter repeat password",
    pleaseMakeSureThePassMatch: "Please make sure the passwords match.",
    alreadyHaveAnAccount: "Already have an account?",
    typeYourEmailToReceiveCode: "Type your email to recieve a reset code",
    enterCode: "Enter code",
    resetPassword: "Reset Password",
    youDontHaveAnyProductsYet: "You don't have any products uploaded yet",
    delete: "Delete",
    hello: "Hello",
    nameMustBeLonger: "Name must be longer than 4 characters",
    changePassword: "Change password",
    deleteAccount: "Delete account",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmPassword: "Confirm password",
    newName: "New name",
    pageNotFound: "404 - Page Not Found",
    pageDoesntExist: "The requested page does not exist.",
    goToHome: "Go to Home page",
  },

  bg: {
    home: "Начало",
    termsOfUse: "Условия",
    allRightsReserved: "© 4Her LTD 2023 Всички права запазени",
    login: "Вход",
    buyOrSell:
      "Представяме ви нашия нов дамски пазар! Намерете дрехи, обувки, козметика и аксесоари за покупка или продажба. Открийте своята перфектна форма и стил, безпроблемно. Присъединете се към нас днес и пазарувайте стилни и достъпни артикули!",

    downloadApp: "Свалете приложението безплатно:",
    downloadOnApp: "Свалете от App Store",
    downloadOnGoogle: "Свалете от Google Play",
    passwordHelperText:
      "Използвайте комбинация от 8 главни и малки букви, цифри и специални знаци",
    help: "Помощ",
    name: "Име",
    email: "Имейл",
    message: "Съобщение",
    send: "Изпрати",
    messageSent: "Съобщението е изпратено!",
    language: "Език",
    bulgarian: "Български",
    english: "Английски",
    privacyPolicy: "Правила",
    iHaveReadAndAgreeToTheTermsOfUse:
      "Прочетох и се съгласявам с Правилата за използване",
    continue: "Продължи",
    close: "Затвори",
    decliningTermsOfUse:
      "Отказът от тези условия за използване ще доведе до незабавно излизане от приложението.",
    areYouSureYouWantToProceed: "Сигурни ли сте че искате да излезнете?",
    yes: "Да",
    no: "Не",
    getTheApp: "Свалете приложението",
    settings: "Настройки",
    myProducts: "Моите продукти",
    favourites: "Любими",
    signOut: "Изход",
    confirmEmail: "Потвърди имейл",
    confirmAccount: "Верифицирай акаунт",
    confirmationCode: "Код за потвърдждение",
    appAvailableOn: "Апликацията е достъпна в App Store и Google Play Store",
    clothes: "Дрехи",
    shoes: "Обувки",
    cosmetics: "Козметика",
    accessories: "Аксесоари",
    enhanceYourBeauty:
      "Подчертаите красотата си с нашата широка гама от козметика",
    findThePerfectAcc:
      "Намерете идеалните аксесоари, които да допълнят вашия стил",
    exploreTrendyShoe: "Разгледайте модерна колекция обувки за всеки повод",
    discoverLatestClothing: "Открийте най-новата колекция дрехи",
    loading: "Зареждане...",
    search: "Търси",
    price: "Цена",
    clearFilters: "Изчисти филтрите",
    thereAreNoItems: "Няма продукти, отговарящи на вашите критерии",
    bgn: "лв",
    description: "Описание",
    seller: "Продавач",
    views: "Гледания",
    toContactTheSellerLogin:
      "За да се свържете с продавача, трябва да влезете в акаунта си",
    toUseTheFullFunc:
      "За да имате достъп до пълната функционалност, моля изтеглете нашето приложение:",
    yourEmail: "Вашият имейл",
    youDidntReceiveCode: "Не сте получили код?",
    sendAgain: "Изпрати отново",
    sendCode: "Изпрати Код",
    confirmation: "Потвърждение",
    areYouSureThisActionCannotBeUndone:
      "Сигурен ли си? Това действие не може да бъде отменено.",
    loginToYourAccount: "Влезте във вашия акаунт",
    enterEmail: "Въведете имейл",
    enterPassword: "Въведете парола",
    signIn: "Влез",
    dontHaveAnAccount: "Нямате акаунт?",
    signUp: "Регистрирай се",
    orSignInWith: "или влез с",
    loginWithFacebook: "Влез с фейсбук",
    forgotPassword: "Забравена парола",
    createYourAccount: "Създайте своя акаунт",
    pleaseEnterValidEmail: "Моля, въведете валиден имейл адрес.",
    passwordMustContain:
      "Паролата трябва да съдържа поне 8 символа, включително една главна буква, една малка буква, едно число и един специален символа.",
    enterRepeatPass: "Въведете повторна парола",
    pleaseMakeSureThePassMatch: "Моля, уверете се, че паролите съвпадат.",
    alreadyHaveAnAccount: "Вече имате акаунт?",
    typeYourEmailToReceiveCode:
      "Въведете своя имейл, за да ви изпратим код за смяна на парола",
    enterCode: "Въведете код",
    resetPassword: "Смени парола",
    youDontHaveAnyProductsYet: "Все още нямате качени продукти",
    delete: "Изтрий",
    hello: "Здравей",
    nameMustBeLonger: "Името трябва да съдържа поне 4 символа",
    changePassword: "Смени парола",
    deleteAccount: "Изтрий акаунт",
    oldPassword: "Стара парола",
    newPassword: "Нова парола",
    confirmPassword: "Потвърди парола",
    newName: "Ново име",
    pageNotFound: "404 - Страницата не е намерена",
    pageDoesntExist: "Страницата не съществува.",
    goToHome: "Към началната страница",
  },
};
