import React from "react";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";
import useLanguage from "../hooks/useLanguage";

const DeleteAlert = ({ variant, onClose, handleDelete, handleCancel }) => {
  const { language } = useLanguage();

  return (
    <Toast bg={variant.toLowerCase()} onClose={onClose} className="w-100">
      <Toast.Header style={{ justifyContent: "space-between" }}>
        <strong className="mr-auto">{language.confirmation}</strong>
      </Toast.Header>
      <Toast.Body>
        {language.areYouSureThisActionCannotBeUndone}
        <div className="d-flex justify-content-end mt-2">
          <Button variant="danger" onClick={handleDelete}>
            {language.yes}
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            {language.no}
          </Button>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default DeleteAlert;
