import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsEnvelopeFill, BsFillLockFill } from "../../../icons";
import "./RegisterPage.css";
import FacebookLogin from "react-facebook-login";
import useAuth from "../../../hooks/useAuth";
import { FaFacebook } from "../../../icons";
import Alert from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Spinner from "react-bootstrap/Spinner";
import { Routes } from "../../../common/routes";
import useLanguage from "../../../hooks/useLanguage";

const RegisterPage = () => {
  const { makeRequest, logIn } = useAuth();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    retypePassword: "",
  });
  const [isValid, setIsValid] = useState({
    pass: false,
    retypePass: false,
    email: false,
  });
  const [error, setError] = useState({ message: "", isError: false });
  const [isLoading, setIsLoading] = useState(false);

  const clearInputs = () => {
    setInputs({
      email: "",
      password: "",
      retypePassword: "",
    });
    setIsValid({
      pass: false,
      retypePass: false,
      email: false,
    });
  };

  const handleOnChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const validateInputs = (type, input) => {
    const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (type === "password") {
      setIsValid((prevState) => ({
        ...prevState,
        pass: passRegex.test(input),
      }));
    }

    if (type === "retypePassword") {
      setIsValid((prevState) => ({
        ...prevState,
        retypePass: passRegex.test(input),
      }));
    }
    if (type === "email") {
      setIsValid((prevState) => ({
        ...prevState,
        email: emailRegex.test(input),
      }));
    }
  };

  const handleFacebookLogin = async (response) => {
    if (response.status !== "unknown") {
      try {
        setIsLoading(true);
        await logIn(Routes.LOGIN_FB, response);
        navigate("/");
      } catch (err) {
        setError({
          message: err?.message ? err?.message : "An error has occured",
          isError: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setError({ message: "Login Failed", isError: true });
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const { email, password, retypePassword } = inputs;

    // Perform input validation
    if (!email) {
      setError({ message: "Please enter an email address.", isError: true });
      return;
    }

    if (!isValid.email) {
      setError({
        message: "Please enter a valid email address.",
        isError: true,
      });
      return;
    }

    if (!password) {
      setError({ message: "Please enter a password.", isError: true });
      return;
    }

    if (!isValid.pass) {
      setError({
        message:
          "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.",
        isError: true,
      });
      return;
    }

    if (password !== retypePassword) {
      setError({ message: "Passwords do not match.", isError: true });
      return;
    }

    try {
      // Call your API or perform other actions with the form data
      setIsLoading(true);
      const res = await makeRequest("post", "auth/signup", {
        email: email,
        password: password,
        name: email,
      });
      navigate("/login");
      // Clear the form inputs and validation states
      clearInputs();
    } catch (err) {
      setError({ message: err.detail, isError: true });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="registerPage-container d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="registerPage-container">
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col
            lg={6}
            xl={7}
            className="order-2 order-md-1 d-flex justify-content-center align-items-center"
          >
            <Image
              src={require("../../../4her-logo.png")}
              alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
              fluid
              className="registerPage-logo"
            />
          </Col>
          <Col lg={6} xl={5} className="mt-md-0 order-1 order-md-2">
            {error.isError && (
              <Alert
                message={error.message}
                variant="Danger"
                onClose={() => setError({ message: "", isError: false })}
              />
            )}
            <div className="d-flex justify-content-center align-items-center pb-2">
              {" "}
              <h1>{language.createYourAccount}</h1>
            </div>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="email" className="pb-2">
                <div className="icon-input-group">
                  <BsEnvelopeFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="email"
                    placeholder={language.enterEmail}
                    value={inputs.email}
                    style={{
                      backgroundColor: "#F7F0F5",
                      border: "none",
                    }}
                    onChange={(e) => {
                      handleOnChange(e.target.value, "email");
                      validateInputs("email", e.target.value);
                    }}
                    onBlur={() => validateInputs("email", inputs.email)}
                    className="registerPage-formControl"
                  />
                </div>
                {!isValid.email && inputs.email.length > 0 && (
                  <Form.Text className="text-danger">
                    {language.pleaseEnterValidEmail}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="password" className="pt-2 pb-2">
                <div className="icon-input-group">
                  <BsFillLockFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="password"
                    placeholder={language.enterPassword}
                    value={inputs.password}
                    style={{
                      backgroundColor: "#F7F0F5",
                      border: "none",
                    }}
                    onChange={(e) => {
                      handleOnChange(e.target.value, "password");
                      validateInputs("password", e.target.value);
                    }}
                    onBlur={() => validateInputs("password", inputs.password)}
                    className="registerPage-formControl"
                  />
                </div>
                {!isValid.pass && inputs.password.length > 0 && (
                  <Form.Text className="text-danger">
                    {language.passwordMustContain}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="retypePassword" className="pt-2 pb-2">
                <div className="icon-input-group">
                  <BsFillLockFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="password"
                    placeholder={language.enterRepeatPass}
                    value={inputs.retypePassword}
                    style={{
                      backgroundColor: "#F7F0F5",
                      border: "none",
                    }}
                    onChange={(e) => {
                      handleOnChange(e.target.value, "retypePassword");
                      validateInputs("retypePassword", e.target.value);
                    }}
                    onBlur={() =>
                      validateInputs("retypePassword", inputs.retypePassword)
                    }
                    className="registerPage-formControl"
                  />
                </div>
                {!(inputs.password === inputs.retypePassword) &&
                  !!inputs.retypePassword.length && (
                    <Form.Text className="text-danger">
                      {language.pleaseMakeSureThePassMatch}
                    </Form.Text>
                  )}
              </Form.Group>
              <div className="registerPage-text-center">
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-2"
                  style={{
                    backgroundColor:
                      !isValid.pass || !isValid.retypePass || !isValid.email
                        ? "#85D1AB"
                        : "#99EDC3",
                    border: "none",
                    fontWeight: "bold",
                    color: "#373737",
                  }}
                  disabled={
                    !isValid.pass || !isValid.retypePass || !isValid.email
                  }
                >
                  {language.signUp}
                </Button>
                <div className="d-inline pt-2 pb-2">
                  <p
                    className="loginPage-signup-text"
                    style={{ fontWeight: "bold", display: "inline" }}
                  >
                    {language.alreadyHaveAnAccount}{" "}
                  </p>
                  <Nav.Link
                    onClick={() => navigate("/login")}
                    eventKey="2"
                    style={{
                      color: "#C2AFF0",
                      fontWeight: "bold",
                      display: "inline",
                    }}
                  >
                    {language.signIn}
                  </Nav.Link>
                </div>
                <p className="registerPage-signup-text mt-1">
                  {language.orSignInWith}
                </p>
              </div>
              <div className="facebook-login">
                <FacebookLogin
                  appId="479511497544500"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookLogin}
                  cssClass="facebook-button"
                  textButton={
                    <div className="facebook-button-content">
                      <FaFacebook className="facebook-icon" />
                      <span style={{ fontWeight: "bold" }}>
                        {language.loginWithFacebook}
                      </span>
                    </div>
                  }
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegisterPage;
