import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./HomePage.css";
import useProduct from "../../hooks/useProduct";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import TermsOfUseModal from "../../components/TermsOfUseModal";
import useAuth from "../../hooks/useAuth";
import { Methods } from "../../common/methods";
import { Routes } from "../../common/routes";
import Spinner from "react-bootstrap/Spinner";
import accessoriesIcon from "../../accessories.svg";
import cosmeticsIcon from "../../cosmetics.svg";
import dressIcon from "../../dress.svg";
import shoesIcon from "../../shoes.svg";
import useLanguage from "../../hooks/useLanguage";

const HomePage = () => {
  const navigate = useNavigate();
  const { user, signOut, setCurrUser, makeRequest } = useAuth();
  const { getCategories } = useProduct();
  const { language } = useLanguage();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTermsOfUse = async () => {
    try {
      setIsLoading(true);
      const res = await makeRequest(
        Methods.POST,
        Routes.CONFIRM_TERMS_OF_USE,
        {}
      );
      setCurrUser(res);
    } catch (err) {
      // setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="homePage-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="homePage-container">
      <Container>
        <Row>
          <Col md={6} lg={6}>
            <Nav.Link
              onClick={() => navigate("/drehi")}
              style={{
                display: "inline",
              }}
            >
              <Card
                className="placeholder-style"
                style={{ backgroundColor: "#F7F0F5", border: "none" }}
              >
                <Card.Body>
                  <div className="icon">
                    <img
                      src={dressIcon}
                      alt="Dress Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <Card.Title className="title-style">
                    {language.clothes}
                  </Card.Title>
                  <Card.Text color="#373737">
                    {language.discoverLatestClothing}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Nav.Link>
          </Col>
          <Col md={6} lg={6}>
            <Nav.Link
              onClick={() => navigate("/obuvki")}
              style={{
                display: "inline",
              }}
            >
              <Card
                className="placeholder-style"
                style={{ backgroundColor: "#F7F0F5", border: "none" }}
              >
                <Card.Body>
                  <div className="icon">
                    <img
                      src={shoesIcon}
                      alt="Shoes Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <Card.Title className="title-style">
                    {" "}
                    {language.shoes}
                  </Card.Title>
                  <Card.Text color="#373737">
                    {language.exploreTrendyShoe}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Nav.Link>
          </Col>
          <Col md={6} lg={6}>
            <Nav.Link
              onClick={() => navigate("/kozmetika")}
              style={{
                display: "inline",
              }}
            >
              <Card
                className="placeholder-style"
                style={{ backgroundColor: "#F7F0F5", border: "none" }}
              >
                <Card.Body>
                  <div className="icon">
                    <img
                      src={cosmeticsIcon}
                      alt="Cosmetics Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <Card.Title className="title-style">
                    {" "}
                    {language.cosmetics}
                  </Card.Title>
                  <Card.Text color="#373737">
                    {language.findThePerfectAcc}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Nav.Link>
          </Col>
          <Col md={6} lg={6}>
            <Nav.Link
              onClick={() => navigate("/aksesoari")}
              style={{
                display: "inline",
              }}
            >
              <Card
                className="placeholder-style"
                style={{ backgroundColor: "#F7F0F5", border: "none" }}
              >
                <Card.Body>
                  <div className="icon">
                    <img
                      src={accessoriesIcon}
                      alt="Accessories Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <Card.Title className="title-style">
                    {" "}
                    {language.accessories}
                  </Card.Title>
                  <Card.Text color="#373737">
                    {language.enhanceYourBeauty}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Nav.Link>
          </Col>
        </Row>
      </Container>
      <TermsOfUseModal
        visible={user && !user.acceptedTermsOfUsage ? true : false}
        onClose={async () => {
          await signOut();
          navigate("/");
        }}
        onContinue={async () => await handleTermsOfUse()}
      />
    </div>
  );
};

export default HomePage;
