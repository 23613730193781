import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

const LoginToContactSeller = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();
  const currentUrl = location.pathname;

  return (
    <Card
      className="w-100"
      style={{
        backgroundColor: "#F7F0F5",
        border: "none",
      }}
    >
      <Card.Body>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Card.Text>{language.toContactTheSellerLogin}</Card.Text>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#c2aff0",
                borderColor: "#c2aff0",
                color: "#373737",
              }}
              onClick={() =>
                navigate(`/login?redirect=${encodeURIComponent(currentUrl)}`)
              }
            >
              {language.login}
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LoginToContactSeller;
