import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLanguage from "../../hooks/useLanguage";

const NotFoundPage = () => {
  const { language } = useLanguage();

  return (
    <Container>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col xs={12} sm={6} md={4} className="text-center">
          <h1>{language.pageNotFound}</h1>
          <p>{language.pageDoesntExist}</p>
          <Button
            as={Link}
            to="/"
            variant="primary"
            style={{
              backgroundColor: "#99EDC3",
              border: "none",
              color: "#373737",
              fontWeight: "bold",
            }}
          >
            {language.goToHome}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
