import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsEnvelopeFill, BsFillLockFill } from "../../icons";
import "./LoginPage.css";
import FacebookLogin from "react-facebook-login";
import useAuth from "../../hooks/useAuth";
import { FaFacebook } from "../../icons";
import Alert from "../../components/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Routes } from "../../common/routes";
import Spinner from "react-bootstrap/Spinner";
import useLanguage from "../../hooks/useLanguage";

const LoginPage = () => {
  const { logIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();
  const redirectUrl = new URLSearchParams(location.search).get("redirect");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ message: "", isError: false });
  const [isLoading, setIsLoading] = useState(false);

  const handleFacebookLogin = async (response) => {
    if (response.status !== "unknown") {
      try {
        setIsLoading(true);
        await logIn(Routes.LOGIN_FB, response);
        navigate("/");
      } catch (err) {
        setError({
          message: err?.message ? err?.message : "An error has occured",
          isError: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setError({ message: "Login Failed", isError: true });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await logIn(Routes.LOGIN_NORMAL, {
        username: email,
        password,
      });
      navigate(redirectUrl || "/");
      setEmail("");
      setPassword("");
    } catch (err) {
      setError({
        message: err?.detail ? err?.detail : "An error has occured",
        isError: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="loginPage-container d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="loginPage-container">
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col
            lg={6}
            xl={7}
            className="order-2 order-md-1 d-flex justify-content-center align-items-center"
          >
            <Image
              src={require("../../4her-logo.png")}
              alt="Logo of 4Her - Women's Marketplace for empowering women in various fields"
              fluid
              className="loginPage-logo"
            />
          </Col>
          <Col lg={6} xl={5} className="mt-md-0 order-1 order-md-2">
            {error.isError ? (
              <Alert
                message={error.message}
                variant="Danger"
                onClose={() => setError({ message: "", isError: false })}
              ></Alert>
            ) : null}
            <div className="d-flex justify-content-center align-items-center pb-2">
              {" "}
              <h1>{language.loginToYourAccount}</h1>
            </div>

            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="email" className="pb-2">
                <div className="icon-input-group">
                  <BsEnvelopeFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="email"
                    placeholder={language.enterEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ backgroundColor: "#F7F0F5", border: "none" }}
                    className="loginPage-formControl"
                  />
                </div>
              </Form.Group>
              <Form.Group controlId="password" className="pt-2 pb-2">
                <div className="icon-input-group">
                  <BsFillLockFill className="input-icon" color="#C2AFF0" />
                  <Form.Control
                    type="password"
                    placeholder={language.enterPassword}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      backgroundColor: "#F7F0F5",
                      border: "none",
                    }}
                    className="loginPage-formControl"
                  />
                </div>
              </Form.Group>
              <div className="loginPage-text-center">
                <Form.Text className="mb-1 mt-2">
                  <Nav.Link
                    className="forgot-password-link"
                    style={{
                      color: "#C2AFF0",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                    onClick={() => navigate("/forgotPass")}
                  >
                    {language.forgotPassword}
                  </Nav.Link>
                </Form.Text>
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-1 w-100"
                  style={{
                    backgroundColor: "#99EDC3",
                    border: "none",
                    color: "#373737",
                    fontWeight: "bold",
                  }}
                >
                  {language.signIn}
                </Button>
                <p
                  className="loginPage-signup-text"
                  style={{ fontWeight: "bold" }}
                >
                  {language.dontHaveAnAccount}{" "}
                  <Nav.Link
                    onClick={() => navigate("/signUp")}
                    style={{
                      display: "inline",
                      color: "#C2AFF0",
                      fontWeight: "bold",
                    }}
                  >
                    {language.signUp}
                  </Nav.Link>
                </p>
                <p
                  className="loginPage-signup-text mt-1"
                  style={{ color: "#373737" }}
                >
                  {language.orSignInWith}
                </p>
              </div>
              <div className="facebook-login">
                <FacebookLogin
                  appId="479511497544500"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookLogin}
                  cssClass="facebook-button"
                  textButton={
                    <div className="facebook-button-content">
                      <FaFacebook className="facebook-icon" />
                      <span style={{ fontWeight: "bold" }}>
                        {language.loginWithFacebook}
                      </span>
                    </div>
                  }
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
