import React, { useState } from "react";
import { Modal, Image, Button, Container, Row, Col } from "react-bootstrap";
import useLanguage from "../hooks/useLanguage";
import ReactMarkdown from "react-markdown";
import "./TermsOfUseModal.css";

const TermsOfUseModal = ({ visible, onClose, onContinue }) => {
  const { language, termsOfUse } = useLanguage();
  const [accepted, setAccepted] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  return (
    <Modal show={visible} onHide={onClose} animation={false} centered>
      <Modal.Body>
        {showPrompt ? (
          <Container className="text-center">
            <Row>
              <Col>
                <Image src={require("../4her-logo.png")} fluid />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="font-weight-bold mt-4 mb-3">
                  {language.decliningTermsOfUse}
                </h5>
                <p>{language.areYouSureYouWantToProceed}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="outline-danger" onClick={onClose}>
                  {language.yes}
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => setShowPrompt(false)}
                  className="termsOfUseModal-button-no"
                >
                  {language.no}
                </Button>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <h5 className="mb-4 font-weight-bold">{language.termsOfUse}</h5>
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                border: "1px solid black",
                padding: "0.5rem",
              }}
            >
              <ReactMarkdown children={termsOfUse} />
            </div>
            <div className="d-flex align-items-center mt-3">
              <input
                type="checkbox"
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
              />

              <p className="mb-0" style={{ paddingLeft: "4px" }}>
                {language.iHaveReadAndAgreeToTheTermsOfUse}
              </p>
            </div>
          </>
        )}
      </Modal.Body>
      {!showPrompt ? (
        <Modal.Footer>
          <Button variant="success" onClick={onContinue} disabled={!accepted}>
            {language.continue}
          </Button>
          <Button variant="danger" onClick={() => setShowPrompt(true)}>
            {language.close}
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default TermsOfUseModal;
