import React from "react";
import Toast from "react-bootstrap/Toast";

const Alert = ({ message, variant, onClose }) => {
  return (
    <Toast
      bg={variant.toLowerCase()}
      style={{
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 2000,
      }}
      onClose={onClose}
    >
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">4Her</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default Alert;
