import React, { createContext, useContext, useState } from "react";
import { languages } from "../resources/languages";
import { termsOfUseBg } from "../pages/Terms/termsOfUseBg";
import { termsOfUseEn } from "../pages/Terms/termsOfUseEn";
import { privacyPolicyEn } from "../pages/Privacy/privacyPolicyEn";
import { privacyPolicyBg } from "../pages/Privacy/privacyPolicyBg";

const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(languages.en);
  const [termsOfUse, setTermsOfUse] = useState(termsOfUseEn);
  const [privacyPolicy, setPrivacyPolicy] = useState(privacyPolicyEn);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  function changeSelectedLanguageToEnglish() {
    setSelectedLanguage("en");
  }

  function changeSelectedLanguageToBulgarian() {
    setSelectedLanguage("bg");
  }

  function changeLanguageToEnglish() {
    setLanguage(languages.en);
    setTermsOfUse(termsOfUseEn);
    setPrivacyPolicy(privacyPolicyEn);
  }

  function changeLanguageToBulgarian() {
    setLanguage(languages.bg);
    setTermsOfUse(termsOfUseBg);
    setPrivacyPolicy(privacyPolicyBg);
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        selectedLanguage,
        changeLanguageToEnglish,
        changeLanguageToBulgarian,
        changeSelectedLanguageToEnglish,
        changeSelectedLanguageToBulgarian,
        termsOfUse,
        privacyPolicy,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default function useProduct() {
  return useContext(LanguageContext);
}
