export const privacyPolicyEn = `**Privacy Policy of "4Her" Ltd**

This document (the "Policy/Privacy Policy") is intended to inform our clients - natural persons, respectively the representatives of clients - legal entities, or persons specified for contact in connection with the execution of a contract with a client (employees and employees of a legal individual client), regarding how "4Her" Ltd ("we", "us", "our", "Company"), when carrying out its activities, uses or shares the personal data that you provide, or which otherwise received or created by us and that are related to you. It is recommended that you take the time to familiarize yourself with this Privacy Policy and review it periodically by clicking the "Privacy Policy" button on our Platform, as we may change it from time to time.

When you contact us to conclude a contract for the services we offer, or on any other occasion, you provide us with certain information about yourself - such as names, address.

According to the legislation, including Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons in connection with the processing of personal data and on the free movement of such data and on the repeal of Directive 95/ 46/EC, in force since May 25, 2018 ("Regulation", "GDPR"), this information about you constitutes your personal data, and you are its subject. For our part, we are the Controller of your personal data. The data that identifies us as the Controller and the ways to contact Us are indicated at the end of this document.

Below in this Policy you will find answers to the following questions:

- WHAT PERSONAL DATA DO WE COLLECT?

- HOW DO WE COLLECT AND USE YOUR PERSONAL DATA?

- WHO DO WE SHARE YOUR PERSONAL DATA WITH?

- DO WE TRANSFER PERSONAL DATA OUTSIDE BULGARIA?

- HOW LONG DO WE STORE YOUR PERSONAL DATA?

- HOW PROTECTED IS YOUR PERSONAL DATA?

- WHAT RIGHTS DO YOU HAVE?

- WHAT IS IMPORTANT TO KNOW WHEN EXERCISING YOUR RIGHTS?

- HOW WILL WE NOTIFY YOU OF CHANGES TO THE CONTENT IN THIS PRIVACY POLICY?

- HOW TO CONTACT US - WHO WE ARE?

When you contact us through our Application or through our website to create an account and use our services, i.e. in order to enter into a contract for the provision of services or to obtain information, ask questions or assert your rights, we may directly receive from you or ask you to provide us with certain information about yourself.

Your personal data, which we collect in connection with the conclusion and execution of service contracts concluded with you, are defined in the applicable legal acts.

The personal data we collect may include:

1\. Data regarding physical identity - three names

2\. Contact information - email address, phone number;

3\. Data regarding professional activity -- position or position in the organization you work for; For example, personal data regarding your position in the company you represent and the presence of representative authority/authorization to create a profile on our Application;

When we process your personal data for the purposes of our main business, as well as to fulfill our statutory obligations, this processing is mandatory for the fulfillment of these purposes. Without this data, we would not be able to carry out our business. For example, if you do not provide us with your names and email address, alternatively if you do not use a login with a social network profile, we would not be able to conclude a contract with you to create a user profile on our Application or provide you with the necessary information for its implementation. In other cases, when collecting your personal data, we will notify you whether the provision of data is necessary and what the consequences are if you refuse.

**How do we collect and use your personal data?**

We process your personal data based on the following legal grounds:

- In connection with creating your user profile, i.e. for the conclusion and performance of a contract for services offered by us, which we conclude with you; or

- To fulfill our obligations that follow from a regulatory act, for example in connection with obligations provided for in the Accounting Act and the Tax and Insurance Procedural Code or when providing information to judicial and other state authorities; or

- Our or a third party's legitimate interest, except when your interests or your fundamental rights and freedoms take precedence over it. Examples of our legitimate interest are establishing, exercising or defending legal claims; cyber security and protection against hacker attacks and improving the operation and quality of our services;

- Your explicit informed consent in some cases, for example for the purposes of direct marketing or for the provision of sensitive personal data or for you to send enquiries, requests and complaints, unless there is no other reason for this. Your given consent can be withdrawn at any time by writing to us at the addresses and email for contacting us indicated at the end of this document, in which case we will cease to process your data subject to the withdrawn consent.

**We use your personal data for one or more of the purposes listed below:**

1\. To fulfill our obligations in the performance of the contract concluded with you, i.e. in connection with serving your user profile;

2\. To contact you when we have asked you a question or you have made a complaint or request for a service offer, or to notify you of changes to our work or a data security breach ; to send you electronic communications about our products and services when you are our customer (direct marketing);

3\. To comply with legal, regulatory and other requirements (such as in the field of tax law and accounting), to carry out our activities in accordance with applicable legislation and professional norms and rules, to respond to requests from local or foreign regulatory, state or judicial authorities;

4\. To improve the services we provide, including for internal purposes such as audits, analytics and surveys to help us improve our operations or to monitor and analyze trends and usage of our services;

5\. In relation to legal claims, to protect our interests in legal disputes or other proceedings instituted by competent authorities, as well as for purposes specified elsewhere in this Policy.

**Who do we share your personal data with?**

In carrying out our activity, we have to share your personal data with some of the following persons:

- Our service providers (for the purposes of providing and maintaining information systems and ensuring information security, etc.)

- Our lawyers, professional consultants and auditors;

- Regulatory, state and judicial authorities, which by virtue of a normative act have the authority to demand the provision of information, including personal data, such as - courts, prosecutor's office, various authorities with powers to protect national security and public order, etc. .

In all cases, we enter into written contracts with the service providers we work with, requiring them to take the necessary measures to ensure the protection of your personal data. We will only provide these contractors with the information they need to provide the contracted services to us, without allowing them to use your information for their own purposes. We will not provide your personal data to third parties to send you unsolicited marketing messages, unless you have given the necessary express consent for this. Please note that we are not responsible for the activities of any third parties in relation to the processing of your personal data.

Transfer of personal data outside the Republic of Bulgaria

We do not transfer your personal data to third parties outside the EU and EEA.

In any case of a possible future transfer of personal data, "4Her" Ltd. guarantees the security and confidentiality of your personal data by implementing all necessary technical and organizational measures to protect personal data.

Your personal data will be transferred under the terms of signed Standard Contractual Clauses approved by the European Commission.

**How long do we store your personal data?**

We have an internal policy that determines how long we keep your personal data. It is constructed based on (a) the type of information we collect and (b) the purposes for which we collect it.

In principle, we store your personal data for as long as is necessary for the processing purposes for which the data were collected and for any other permissible and related purpose, or until the expiration of a legally defined period.

When we process your personal data for direct marketing purposes based on your express, informed written consent, we limit the storage of the data to 2 (two) years from the moment of obtaining the relevant consent.

It is our legitimate interest to retain certain of your personal information until the expiry of the limitation period for making claims (5 years) after the expiry or termination of the contract with you. We will not delete or anonymize your personal data if it is necessary for pending judicial or administrative proceedings or proceedings to examine your complaint against us.

**How secure is your personal data?**

We value the privacy of your personal data highly and take the security measures of the personal data we collect and store very seriously.

We use numerous technical and organizational measures, appropriate in light of the sensitivity of the information we maintain, to protect the personal data you provide to us from unauthorized access, use or disclosure. For example, we encrypt communications using an SSL encrypted connection, have firewalls and anti-virus programs, access control tools, segregation of duties, cryptographic protection, password protection and identification in transit, and more.

We have adopted policies and procedures regulated by Orders of the company manager, while at the same time we have designated data protection officers, the registers that will be maintained, including on paper, access to them, the storage terms of the processed personal data, the procedures for their destruction.

We require our suppliers and partners who have access to your personal data to use appropriate measures to ensure the protection and confidentiality of your personal data. However, you are also responsible for protecting your personal information that you share with us over the Internet. Unfortunately, the transmission of information over the Internet or by telephone may not be completely secure, despite the measures taken by us, given that it passes through the networks, channels and platforms of third party electronic service providers. Therefore, please note that the transmission of your personal information via the Internet or by telephone is done at your own risk.

**What rights do you have?**

In relation to your personal data, you have certain rights against us, which are granted to you by the GDPR and other applicable legislation. Sometimes certain rights may arise and be exercised only on certain grounds for processing your personal data; your other rights are subject to certain limitations and exclusions by law. To exercise your rights or ask questions, you should address your request to the email or contact address below.

**Under applicable law, you have the following rights:**

1\. Right of **access** to the personal data related to you;

2\. The right to **request correction** of inaccurate personal data relating to you;

3\. Right to request **restriction** of processing of personal data related to you;

4\. Right to **object** to the processing of your personal data;

5\. The right to **request deletion** of personal data relating to you ("the right to be forgotten");

6\. The right to **receive the personal data that you have** provided to us and which concerns you and to use it again by transferring it to another controller ("right to portability");

7\. Right to **submit a complaint to the competent supervisory** authority or to the court in case your rights have been violated or you have suffered from unlawful processing of your personal data. The competent body for the protection of personal data in the Republic of Bulgaria is the Commission for the Protection of Personal Data, with an address in the city of Sofia, Tsvetan Lazarov Blvd. No. 2, with e-mail: kzld@cpdp.bg and website: www. cpdp.bg;

8\. Where the processing is based on your consent, you have the right to withdraw your consent to the processing of your personal data at any time by writing to us at the addresses and email for contacting us at the end of the document, without prejudice to the legality of processing based on your consent before it is withdrawn.

You have the right of access and can request more detailed information about whether we process your personal data, what categories, for what purposes, to whom we disclose it, etc. If you wish, we will provide you with access to your personal data that is being processed, in the form of a copy of it. The copy is free for you. If you request additional copies or individually formatted or more detailed information, or disproportionately (abusively) exercise your rights, we may charge you a reasonable fee to cover our administrative costs of producing them. If you submitted the request by electronic means, we will, where possible, provide you with the information in a widely used electronic form, unless you have asked us otherwise.

If we do not process your personal data, we will notify you. If we refuse your request for a copy of the data, we will explain to you the reasons for this decision.

The exercise of your right of access shall not adversely affect the rights or freedoms of others, including trade secret or intellectual property, and in particular copyright protection of software. In the event that we consider that there is a reason to expect such a negative impact, we can motivatedly limit some of the information that we will provide to you so that it does not occur.

If we process a large amount of information about you, we may ask you to specify precisely the information or processing activities to which your request relates. This way you will help us to navigate better and faster, and you yourself will get the data you need sooner.

If you have objected to the processing of your personal data based on our legitimate interest or the legitimate interest of a third party, we may continue the processing despite your objection if we can demonstrate that there are compelling legitimate grounds for the processing that override your interests , rights and freedoms, or for the establishment, exercise or defense of legal claims. In the event that data relating to you is used for direct marketing purposes, you have the right to object free of charge and at any time to its processing for this purpose and we will stop processing it by notifying us in writing at the addresses indicated at the end of this Notice in the How to Contact Us section.

When you want us to correct your personal data, you may request that we also notify the third parties to whom it was disclosed, except in cases where this is impossible or involves excessive efforts.

You have the right to request the deletion of your personal data when they are no longer necessary for the purposes for which they were collected or otherwise processed; when you withdraw your consent to the processing of your personal data and there is no other legal basis for the processing; when you object to processing based on a legitimate interest and it does not take precedence over your rights, freedoms and interests; when processing without a legal basis or deleting your personal data is our legal obligation, determined by the law of the Republic of Bulgaria or The European Union. Under current legislation, we have the right to continue processing despite your request for deletion in order to comply with our legal obligations under the law of the Republic of Bulgaria or the European Union, which require the processing of your personal data or when necessary for the establishment, exercise or the defense of legal claims.

In order to exercise the right to restrict processing, the following conditions must be met:

- You dispute the accuracy of personal data;

- The processing is without legal basis, but instead of asking us to delete them, you request a limitation of their use;

- We no longer need the personal data for the purposes of processing, but you require it for the establishment, exercise or defense of legal claims;

- You have objected to the processing on the grounds of "legitimate interest".

Where the processing of your personal data has been restricted, we may still continue to process it in two cases:

- with your express consent; or

- for the establishment, exercise or defense of legal claims or to protect the rights of another natural person or due to important grounds of public interest for the Union or a member state.

The right to portability exists and can be exercised only when the following two conditions are met: (1) processing is in question in an automated manner (i.e. this right does not relate to data processing in the form of paper files) and (2) apart from being automated, the processing of your personal data is based on (a) your consent or (b) a contract to which you are a party or to take steps at your request prior to entering into a contract. You have the right to receive your personal data in a structured, widely used and machine-readable format, or to request the direct transfer of your personal data to another controller, where this is technically feasible.

You should be aware that when you exercise the right to portability, this does not result in the deletion of your data from our systems. You will be able to continue using our services even after the data portability operation. Data portability also does not affect the initial retention period that applies to transmitted data. You can exercise your remaining rights which are set out in law and which we have listed here, while We continue to process the data.

If you believe that we are in violation of applicable law, please contact us for clarification. Of course, you also have the right to immediately file a complaint with a supervisory authority in the European Union where you live, work, or where the alleged violation of your rights took place. The supervisory body for the protection of personal data in Bulgaria is the Commission for the Protection of Personal Data, with an address in the city of Sofia, p.k. 1592, Tsvetan Lazarov Blvd. No. 2, e-mail: kzld@cpdp.bg, website: www.cpdp.bg, contact phone: 02/91-53-519, 02/91-53-555. You can also seek protection of your rights through court proceedings.

**What is important to know when exercising your rights?**

**How to claim the exercise of our rights?** To exercise your rights under the Regulation you can write to us by e-mail or send us a letter by post to the contact details indicated below.

Applications in connection with the exercise of your rights are generally submitted in person or by a person expressly authorized by you. When there are normative procedural rules in relation to the exercise of your rights (in the Regulation, the Personal Data Protection Act and other acts) they should also be observed.

**In what form will we answer you?** In the form in which you sent your inquiry to us - in writing on paper or in electronic form. Where you have submitted a request by electronic means, where possible, the information will be provided to you in a widely used electronic form, unless you have requested otherwise.

**How soon will you receive a response?** Within one month of receiving your request, we will provide you with information about the actions we have taken on it. If necessary, this period can be extended by another two months, taking into account the complexity and number of requests. If such an extension is necessary, we will notify you within one month of submitting your request, explaining the reasons for this extension.

Doubts about your identity. Where we have reasonable concerns regarding the identity of the natural person making the request to us, we may request the provision of additional information necessary to confirm the identity of the data subject. If we do not receive such information and are unable to identify the data subject, we may refuse to act on a request made to us to exercise any of the rights set forth in this Policy.

Where a citizen makes requests to us that are manifestly unfounded or excessive, in particular because of their repetition, we have the right to either: a) charge a reasonable fee, taking into account the administrative costs of providing the information or communication or taking the requested action , or b) refuse to act on the request.

**How will we notify you of changes to the content of this Policy?**

We may periodically update the information in this Policy in response to the development of our business and changing legal requirements. We will notify you in the event that we would like or need to use your personal data for purposes and in a manner significantly different from what we have informed you about and, if necessary, we will seek your consent.

We will notify you of substantive changes to this document by placing a banner - an informational message upon logging into your user profile on our Platform.

**How to contact us?**

To exercise some of your rights under applicable data protection legislation or to contact us if you have any questions regarding this document, you can contact us using one of the forms below contact:

**Person in charge of personal data protection** in 4Her Ltd - Rumyana Yordanova, with correspondence address: city of Sofia, 13B Tintyava St., floor 6, email address: <rumyana.yordanova@penkov-markov.eu>

**The controller of personal data is 4Her Ltd with registered seat and address: Sofia, 19 Lavele St., 4th floor, office 5.**`;
