export const Routes = {
  CATEGORIES: "categories",
  PRODUCTS: "posts/filter",
  LOGIN_NORMAL: "auth/login",
  LOGIN_FB: "auth/facebook",
  FORGOT_PASS: "auth/forgotPassword",
  FORGOT_PASS_RESET: "auth/forgotPassword/newPassword",
  PRODUCTVIEWS: "posts/post/",
  FAVOURITE: "posts/favourite/",
  MYPRODUCTS: "posts/byUser",
  LOGOUT: "auth/logout",
  RESET_PASS: "auth/changePassword",
  DELETE_USER: "auth/user/delete",
  POSTS: "posts",
  ARCHIVE_CHATS: "chats/archive",
  CONFIRM_TERMS_OF_USE: "auth/user/confirmTermsOfUse",
  CHANGE_NAME: "auth/user/changeName",
  SEND_CONFIRMATION_MAIL: "auth/resendEmailConfirmation",
  CONFIRM_MAIL: "auth/confirmEmail",
  REFRESH: "auth/refresh",
};
