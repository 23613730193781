import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "./MyCatalogPage.css";
import CatalogItem from "../../components/CatalogItem";
import useProduct from "../../hooks/useProduct";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import useLanguage from "../../hooks/useLanguage";

const MyCatalogPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const { myProducts, getMyProducts, removeMyProducts } = useProduct();
  const [currProducts, setCurrProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getMyProducts().then(() => setIsLoading(false));
    return () => {
      setCurrProducts([]);
      removeMyProducts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrProducts([...myProducts]);
  }, [myProducts]);

  const handleProductClick = (product) => {
    const id = product.id;
    const title = product.title;
    navigate(`${location.pathname}/${id}/${title}`);
  };

  if (isLoading) {
    return (
      <div className="productPage-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{language.loading}</span>
        </Spinner>
      </div>
    );
  }

  if (!isLoading && !myProducts.length) {
    return (
      <div className="myCatalogPage-container d-flex justify-content-center align-items-center">
        <span>{language.youDontHaveAnyProductsYet}</span>
      </div>
    );
  }

  return (
    <div className="myCatalogPage-container">
      <Container>
        <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
          {currProducts.map((product) => (
            <CatalogItem
              key={product.id}
              product={product}
              disableFavourite
              onClick={() => handleProductClick(product)}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default MyCatalogPage;
