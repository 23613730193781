import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import TermsOfUse from "./pages/Terms/TermsOfUse";
import "./App.css";
import Help from "./pages/Help/HelpPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import CatalogPage from "./pages/Home/Catalog/CatalogPage";
import LoginPage from "./pages/Auth/LoginPage";
import ForgotPass from "./pages/Auth/ForgotPass/ForgotPass";
import RegisterPage from "./pages/Auth/Register/RegisterPage";
import ProductPage from "./pages/Home/Product/ProductPage";
import MyCatalogPage from "./pages/MyCatalog/MyCatalogPage";
import MyProductPage from "./pages/MyCatalog/MyProductPage.jsx/MyProductPage";
import FavouritePage from "./pages/Favourite/FavouritePage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import useAuth from "./hooks/useAuth";
import SettingsPage from "./pages/Settings/SettingsPage";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import PrivacyPolicy from "./pages/Privacy/PrivacyPolicy";
import ConfirmAccountPage from "./pages/ConfirmAccount/ConfirmAccountPage";
import DownloadAppModal from "./components/DownloadAppModal";
import useLanguage from "./hooks/useLanguage";
import { Container } from "react-bootstrap";

const App = () => {
  const { user } = useAuth();
  const { language } = useLanguage();

  const [downloadAppModalShow, setDownloadAppModalShow] = useState(false);

  const openDownloadAppModal = () => {
    setDownloadAppModalShow(true);
  };

  const closeDownloadAppModal = () => {
    setDownloadAppModalShow(false);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isiOS = /iPad|iPhone|iPod/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    if (isiOS || isAndroid) {
      openDownloadAppModal();
    }
  }, []);

  return (
    <div className="w-100 min-vh-100">
      <NavBar />
      <Container className="content">
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/drehi" element={<CatalogPage />}></Route>
          <Route path="/obuvki" element={<CatalogPage />}></Route>
          <Route path="/kozmetika" element={<CatalogPage />}></Route>
          <Route path="/aksesoari" element={<CatalogPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/terms" element={<TermsOfUse />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/forgotPass" element={<ForgotPass />}></Route>
          <Route path="/signUp" element={<RegisterPage />}></Route>
          <Route
            path="/confirmAccount"
            element={<ConfirmAccountPage />}
          ></Route>
          <Route path="/drehi/:id/:title" element={<ProductPage />} />
          <Route path="/obuvki/:id/:title" element={<ProductPage />}></Route>
          <Route path="/kozmetika/:id/:title" element={<ProductPage />}></Route>
          <Route path="/aksesoari/:id/:title" element={<ProductPage />}></Route>
          <Route
            path="/settings"
            element={
              <ProtectedRoute user={user}>
                <SettingsPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/myProducts"
            element={
              <ProtectedRoute user={user}>
                <MyCatalogPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/myProducts/:id/:title"
            element={
              <ProtectedRoute user={user}>
                <MyProductPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/favourites"
            element={
              <ProtectedRoute user={user}>
                <FavouritePage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/favourites/:id/:title"
            element={
              <ProtectedRoute user={user}>
                <ProductPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        <DownloadAppModal
          show={downloadAppModalShow}
          handleClose={closeDownloadAppModal}
          message={language.appAvailableOn}
        />
      </Container>
      <Footer />
    </div>
  );
};
export default App;
