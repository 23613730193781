import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./CatalogPage.css";
import CatalogItem from "../../../components/CatalogItem";
import useProduct from "../../../hooks/useProduct";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoriesMapper } from "../../../common/categoryMapper";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "../../../icons";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import useAuth from "../../../hooks/useAuth";
import useLanguage from "../../../hooks/useLanguage";

const CatalogPage = () => {
  const {
    getCategories,
    getProducts,
    addOrRemovFromFavourites,
    getFavourites,
    favourites,
  } = useProduct();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { language, selectedLanguage } = useLanguage();

  const [activeFilters, setActiveFilters] = useState({});
  const [showedFilters, setShowedFilters] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [originalFilters, setOriginalFilters] = useState({});
  const [priceFromAndTo, setPriceFromAndTo] = useState({
    priceFrom: 0,
    priceTo: 1000,
  });
  const [shoudLoadQueryParams, setshoudLoadQueryParams] = useState(false);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getCategories()
      .then((categories) => {
        const category = CategoriesMapper[location.pathname.replace("/", "")];
        const selectedCat = categories.find((c) => c.name === category);
        setSelectedCat(deepCopyObj(selectedCat));
        setShowedFilters([...selectedCat.filters]);
        return selectedCat;
      })
      .then((selectedCat) => {
        let selectedFilters = {
          ascDsc: "DESC",
          filterValueIds: [],
          offset: 0,
          limit: 12,
          searchInput: "",
          sortBy: "createdAt",
          priceFrom: 0,
          priceTo: 1000,
          categoryId: selectedCat.id,
        };
        setOriginalFilters(deepCopyObj(selectedFilters));

        const searchParams = new URLSearchParams(location.search);

        const queryParams = {};

        for (const [param, value] of searchParams.entries()) {
          queryParams[param] = value;
        }
        selectedFilters = {
          ...selectedFilters,
          ...queryParams,
          filterValueIds: queryParams.filterValueIds
            ? queryParams.filterValueIds.split(",")
            : [],
        };
        setPriceFromAndTo({
          priceFrom: selectedFilters.priceFrom,
          priceTo: selectedFilters.priceTo,
        });

        setActiveFilters(selectedFilters);
        setshoudLoadQueryParams(true);
        return selectedFilters;
      })
      .then(fetchData)
      .then(() => (user ? getFavourites() : null))
      .then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showedFilters.length) {
      populateFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoudLoadQueryParams]);

  const populateFilters = () => {
    let filters = showedFilters;
    activeFilters.filterValueIds.forEach((fvId) => {
      const foundFilterAndValue = findFilterByFilterValueId(filters, +fvId);
      if (foundFilterAndValue) {
        const filterToModify = deepCopyObj(foundFilterAndValue.filter);
        const filterIndex = filters.indexOf(foundFilterAndValue.filter);
        const fvIndex = filters[filterIndex].filterValues.indexOf(
          foundFilterAndValue.filterValue
        );

        filterToModify.name = foundFilterAndValue.filterValue.name;
        filterToModify.filterValues.splice(fvIndex, 1);

        const newFilters = deepCopyObj(filters);

        newFilters.splice(filterIndex, 1, filterToModify);

        filters = newFilters;
      }
    });
    setShowedFilters(filters);
  };

  const findFilterByFilterValueId = (filters, id) => {
    for (const filter of filters) {
      for (const filterValue of filter.filterValues) {
        if (filterValue.id === id) {
          return {
            filter,
            filterValue,
          };
        }
      }
    }
  };

  const fetchData = async (filters) => {
    setError(null);
    setIsLoading(true);

    setQueryParams(filters);
    try {
      const data = await getProducts(filters);
      setItems((prevItems) => [...prevItems, ...data]);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const atBottom = scrollTop + windowHeight >= scrollHeight;

    if (atBottom && !isLoading) {
      if (items.length !== activeFilters.offset) {
        const newFilters = { ...activeFilters, offset: items.length };
        setActiveFilters(newFilters);
        fetchData(newFilters);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleProductClick = (product) => {
    const id = product.id;
    const title = product.title;
    navigate(`${location.pathname}/${id}/${title}`);
  };

  const handleDropdownClick = async (fv, filter, filterIndex, fvIndex) => {
    const newFilterValues = { ...filterValues, [filter.id]: fv.id };
    setFilterValues(newFilterValues);

    if (selectedCat.filters.map((f) => f.name).includes(filter.name)) {
      const filetToModify = deepCopyObj(filter);
      filetToModify.name = fv.name;
      filetToModify.filterValues.splice(fvIndex, 1);

      const newFilters = deepCopyObj(showedFilters);

      newFilters.splice(filterIndex, 1, filetToModify);

      setShowedFilters(newFilters);
    } else {
      const filetToModify = deepCopyObj(filter);
      filetToModify.name = fv.name;

      const oldFilterValue = deepCopyObj(
        selectedCat.filters[filterIndex].filterValues.find(
          (fvv) => fvv.name === filter.name
        )
      );
      filetToModify.filterValues.splice(fvIndex, 1, oldFilterValue);

      const newFilters = deepCopyObj(showedFilters);
      newFilters.splice(filterIndex, 1, filetToModify);

      setShowedFilters(newFilters);
    }

    const searchFilters = {
      ...activeFilters,
      offset: 0,
      filterValueIds: Object.values(newFilterValues),
    };
    setActiveFilters(searchFilters);
    setItems([]);

    await fetchData(searchFilters);
  };

  const deepCopyObj = (obj) => JSON.parse(JSON.stringify(obj));

  const clearFilters = async () => {
    // if (Object.keys(filterValues).length) {
    const searchFilters = deepCopyObj(originalFilters);
    setPriceFromAndTo({ priceFrom: 0, priceTo: 1000 });
    setActiveFilters(searchFilters);
    setShowedFilters(deepCopyObj(selectedCat.filters));
    setFilterValues({});
    setItems([]);
    await fetchData(searchFilters);
    // }
  };

  const onQueryChange = (query) => {
    const newFilters = {
      ...activeFilters,
      searchInput: query,
      offset: 0,
      limit: 12,
    };
    setActiveFilters(newFilters);
    if (query.length === 0) {
      // setQueryParams('query', '');
      setItems([]);
      fetchData(newFilters);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setItems([]);
      fetchData(activeFilters);
    }
  };

  const handleSearchButton = () => {
    setItems([]);
    fetchData(activeFilters);
  };

  const handleRangeChange = (values) => {
    setPriceFromAndTo({
      priceFrom: +values[0],
      priceTo: +values[1],
    });
  };

  const handleDragEnd = async (e) => {
    const newFilters = {
      ...activeFilters,
      priceFrom: e[0],
      priceTo: e[1],
      offset: 0,
      limit: 12,
    };
    setActiveFilters(newFilters);
    setItems([]);
    await fetchData(newFilters);
  };

  const setQueryParams = (filters) => {
    const queryParams = new URLSearchParams();
    if (filters.priceFrom !== 0) {
      queryParams.append("priceFrom", filters.priceFrom);
    }
    if (filters.priceTo !== 1000) {
      queryParams.append("priceTo", filters.priceTo);
    }
    if (filters.searchInput) {
      queryParams.append("searchInput", filters.searchInput);
    }
    if (filters.filterValueIds.length) {
      queryParams.append("filterValueIds", filters.filterValueIds);
    }

    navigate({
      pathname: location.pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  const [favProducts, setFavProducts] = useState([]);

  useEffect(() => {
    setFavProducts([...favourites]);
  }, [favourites]);

  const onFavouriteClick = (event, productId, isFavourite) => {
    event.stopPropagation();
    addOrRemovFromFavourites(productId, isFavourite);
  };

  return (
    <div className="catalogPage-container">
      <Container>
        <Row>
          <Form.Group
            controlId="search"
            className="catalogPage-search-container pt-2 pb-2"
          >
            <div className="icon-input-group w-100">
              <AiOutlineSearch className="input-icon" color="#c2aff0" />
              <Form.Control
                type="text"
                placeholder={language.search}
                onKeyDown={handleKeyPress}
                value={
                  activeFilters.searchInput ? activeFilters.searchInput : ""
                }
                onChange={(e) => onQueryChange(e.target.value)}
                style={{
                  backgroundColor: "#F7F0F5",
                  border: "none",
                }}
                className="catalogPage-formControl"
              />
              <div
                style={{
                  paddingLeft: "4px",
                }}
                onClick={() => handleSearchButton()}
              >
                <Button
                  style={{
                    backgroundColor: "#99EDC3",
                    borderColor: "#99EDC3",
                    color: "#373737",
                    display: "flex",
                    justifyContent: "space-center",
                    alignItems: "center",
                  }}
                >
                  <div> {language.search} </div>
                </Button>
              </div>
            </div>
          </Form.Group>
        </Row>
        <Row className="catalogPage-filters-row mb-3">
          {showedFilters.map((filter, fIndex) => (
            <Col key={filter.id} className="catalogPage-filters">
              <DropdownButton
                key={filter.id}
                title={selectedLanguage === "bg" ? filter.bgName : filter.name}
                className="catalogPage-filters-button"
              >
                {filter.filterValues.map((fv, fvIndex) => (
                  <Dropdown.Item
                    onClick={async () =>
                      await handleDropdownClick(fv, filter, fIndex, fvIndex)
                    }
                    key={fv.id}
                  >
                    {selectedLanguage === "bg" ? fv.bgName : fv.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          ))}
          <Col className="catalogPage-filters">
            <Form className="w-100">
              <Form.Group controlId="range">
                <Form.Label>{language.price}:</Form.Label>
                <div className="slider-container">
                  <Slider
                    range
                    min={0}
                    max={1000}
                    step={1}
                    trackStyle={[{ backgroundColor: "#c2aff0" }]}
                    value={[priceFromAndTo.priceFrom, priceFromAndTo.priceTo]}
                    onChange={handleRangeChange}
                    onAfterChange={async (e) => await handleDragEnd(e)}
                    allowCross={false}
                  />
                  <div className="range-values">
                    <span>{priceFromAndTo.priceFrom} </span>
                    <span> {priceFromAndTo.priceTo}</span>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col className="catalogPage-filters">
            <Button
              onClick={async () => await clearFilters()}
              variant="primary"
              style={{
                backgroundColor: "#c2aff0",
                borderColor: "#c2aff0",
                color: "#373737",
              }}
            >
              {language.clearFilters}
            </Button>
          </Col>
        </Row>
        {!isLoading && !items.length ? (
          <div className="justify-content-center text-center">
            <span>{language.thereAreNoItems} </span>
          </div>
        ) : (
          <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
            {items.map((product) => (
              <CatalogItem
                key={product.id}
                product={product}
                onClick={() => handleProductClick(product)}
                isFavourite={!!favProducts.find((p) => p.id === product.id)}
                onFavClick={(event) =>
                  onFavouriteClick(
                    event,
                    product.id,
                    !!favProducts.find((p) => p.id === product.id)
                  )
                }
                disableFavourite={!user}
              />
            ))}
          </Row>
        )}

        {isLoading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{language.loading}</span>
            </Spinner>
          </div>
        )}
        {isLoading && error && (
          <div className="d-flex justify-content-center mt-3">
            <span>{error}</span>
          </div>
        )}
      </Container>
    </div>
  );
};

export default CatalogPage;
